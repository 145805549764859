import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auth from "./auth/authSlice";
import common from "./common/commonSlice";
import service from "./service/serviceSlice";
import booking from "./booking/bookingSlice";
import payment from "./payment/paymentSlice";
import notification from "./notification/notificationSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const allreducers = combineReducers({
  auth,
  common,
  service,
  booking,
  payment,
  notification,
});

const persistConfig = {
  key: "relocates-vendor",
  version: 1,
  storage,
  whitelist: ["auth", "service", "booking", "payment", "notification"],
};

const rootReducer = (state, action) => {
  if (action.type === "auth/resetAuth") {
    state = {};
  }
  return allreducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
