// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_appStatue__dC7pk{
    display: flex;
    align-items: center;
    width: max-content;
    padding: 4px 12px;
    background-color: #fefaeb;
    border-radius: 8px;
}
.style_appStatue__dC7pk .style_dot__de0Ee{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #f79008;
    margin-right: 6px;
}
.style_appStatue__dC7pk p{
    color: #b54709;
    margin-bottom: 0;
    font-size: 12px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Status/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,UAAU;IACV,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".appStatue{\n    display: flex;\n    align-items: center;\n    width: max-content;\n    padding: 4px 12px;\n    background-color: #fefaeb;\n    border-radius: 8px;\n}\n.appStatue .dot{\n    height: 8px;\n    width: 8px;\n    border-radius: 50%;\n    background-color: #f79008;\n    margin-right: 6px;\n}\n.appStatue p{\n    color: #b54709;\n    margin-bottom: 0;\n    font-size: 12px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appStatue": `style_appStatue__dC7pk`,
	"dot": `style_dot__de0Ee`
};
export default ___CSS_LOADER_EXPORT___;
