import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeServices: {},
  services: {},
};

const ServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServices: (state, { payload }) => {
      let dataSource = payload?.reduce((acc, curr) => {
        return {
          ...acc,
          ["service_" + curr.id]: curr,
        };
      }, {});
      state.services = dataSource;
    },
    setService: (state, { payload }) => {
      if (!state.services["service_" + payload.id]) {
        state.services = {
          ["service_" + payload.id]: payload,
          ...state.services,
        };
      } else {
        state.services["service_" + payload.id] = payload;
      }
    },
    removeService: (state, { payload }) => {
      delete state.services["service_" + payload.id];
    },
    setActiveServices: (state, { payload }) => {
      state.activeServices = payload;
    },
    setActiveService: (state, { payload }) => {
      if (!state.activeServices["service_" + payload.id]) {
        state.activeServices = {
          ["service_" + payload.id]: payload,
          ...state.activeServices,
        };
      } else {
        state.activeServices["service_" + payload.id] = payload;
      }
    },
    removeActiveService: (state, { payload }) => {
      delete state.activeServices["service_" + payload.id];
      if (state.services["service_" + payload.id]) {
        delete state.services["service_" + payload.id];
      }
    },
  },
});

export const {
  setActiveServices,
  setActiveService,
  removeActiveService,
  setServices,
  setService,
  removeService,
} = ServiceSlice.actions;
export default ServiceSlice.reducer;
