import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

// import VendorHeader from "commoncomponent/VendorHeader";
import VendorSidebar from "components/vendorSidebar/VendorSidebar";
import SignIn from "screens/login";
import SignUp from "screens/signup";
import Bookings from "screens/vendor/Bookings";
import VendorDashboard from "screens/vendor/Dashboard";
import Earnings from "screens/vendor/Earnings";
import VendorServices from "screens/vendor/Services";
import VendorSettings from "screens/vendor/Settings";

import { useDispatch, useSelector } from "react-redux";
import { selectToken, setUser } from "store/auth/authSlice";
// import AppMenu from "commoncomponent/AppMenu";
import { APP_URL } from "services/axios";
import { io } from "socket.io-client";
import { useCallback, useEffect } from "react";
import {
  bookingDiscarded,
  bookingDisputeResolved,
  bookingToCompleted,
  bookingToDisputed,
  bookingToInProgress,
  bookingsToAccepted,
  bookingsToDecision,
  bookingsToDecline,
  setBooking,
  setQuote,
} from "store/booking/bookingSlice";
import VendorHeader from "commoncomponent/VendorHeader";
import {
  removeActiveService,
  setActiveService,
  setServices,
} from "store/service/serviceSlice";
import { setTransaction } from "store/payment/paymentSlice";
import CreatePassword from "screens/CreatePassword";
import PageLoader from "components/PageLoader";
import { setLoading } from "store/common/commonSlice";
import ForgotPassword from "screens/forgetPassword";
import { InternetStatus } from "components/InternetStatus";
import Verification from "screens/Verfication";
import { asyncLogOutVendor } from "store/auth/authThunk";

export const ProtectedLayout = () => {
  const location = useLocation();
  const token = useSelector(selectToken);
  const loading = useSelector((state) => state.common.loading);

  if (!token) {
    if (location?.pathname?.includes("bookings")) {
      return <Navigate to={`/login?callbackUrl=${location.pathname}`} />;
    } else {
      return <Navigate to="/login" />;
    }
  }

  return (
    <>
      <VendorHeader />
      <VendorSidebar />
      <InternetStatus />
      {loading && <PageLoader />}
      <Outlet />
    </>
  );
};

const socket = io(APP_URL);

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const handleActiveService = useCallback(
    (record) => {
      record?.deleted || !record?.active
        ? dispatch(removeActiveService(record))
        : dispatch(setActiveService(record));
    },
    [dispatch]
  );

  const handleVendor = useCallback(
    (record) => {
      const { vendorServices, ...user } = record;
      if (user?.active === false) {
        return dispatch(asyncLogOutVendor(true));
      }
      dispatch(setUser({ ...user }));
      dispatch(setServices(record.vendorServices));
    },
    [dispatch]
  );

  const handleBooking = useCallback(
    (record) => {
      dispatch(setBooking(record));
    },
    [dispatch]
  );

  const handleQuote = useCallback(
    (record) => {
      dispatch(setQuote(record));
    },
    [dispatch]
  );

  const handleToDecision = useCallback(
    (record) => {
      dispatch(bookingsToDecision(record));
    },
    [dispatch]
  );

  const handleToDeclined = useCallback(
    (record) => {
      dispatch(bookingsToDecline(record));
    },
    [dispatch]
  );

  const handleToAccepted = useCallback(
    (record) => {
      dispatch(bookingsToAccepted(record));
    },
    [dispatch]
  );

  const handleToInProgress = useCallback(
    (record) => {
      dispatch(bookingToInProgress(record));
    },
    [dispatch]
  );

  const handleToCompleted = useCallback(
    (record) => {
      dispatch(bookingToCompleted(record));
    },
    [dispatch]
  );

  const handleToDispute = useCallback(
    (record) => {
      dispatch(bookingToDisputed(record));
    },
    [dispatch]
  );

  const handleTransaction = useCallback(
    (record) => {
      dispatch(setTransaction(record));
    },
    [dispatch]
  );

  const handleDiscarded = useCallback(
    (record) => {
      dispatch(bookingDiscarded(record));
    },
    [dispatch]
  );

  const handleDisputeResolved = useCallback(
    (record) => {
      dispatch(bookingDisputeResolved(record));
    },
    [dispatch]
  );

  useEffect(() => {
    socket.on(`service/create`, handleActiveService);
    socket.on(`service/update`, handleActiveService);
    socket.on(`service/delete`, handleActiveService);

    if (user?.id) {
      socket.on(`vendor/vendorProfile/update/${user?.id}`, handleVendor);

      socket.on(`vendor/quoteCreated/${user?.id}`, handleBooking);
      socket.on(`vendor/quoteUpdated/${user?.id}`, handleQuote);
      socket.on(`vendor/bookingCommission/${user?.id}`, handleToDecision);
      socket.on(`vendor/bookingDeclined/${user?.id}`, handleToDeclined);
      socket.on(`vendor/bookingAccepted/${user?.id}`, handleToAccepted);

      socket.on(
        `vendor/serviceBooking/inProgress/${user?.id}`,
        handleToInProgress
      );
      socket.on(
        `vendor/serviceBooking/completed/${user?.id}`,
        handleToCompleted
      );

      socket.on(`serviceBooking/dispute/${user?.id}`, handleToDispute);

      socket.on(`serviceBooking/discarded/${user?.id}`, handleDiscarded);

      // socket.on(`vendor/transaction/${user?.id}`, handleTransaction);

      socket.on(
        `serviceBooking/dispute/resolved/${user?.id}`,
        handleDisputeResolved
      );
    }

    return () => {
      socket.off(`service/create`, handleActiveService);
      socket.off(`service/update`, handleActiveService);
      socket.off(`service/delete`, handleActiveService);

      if (user?.id) {
        socket.off(`vendor/vendorProfile/update/${user?.id}`, handleVendor);

        socket.off(`vendor/quoteCreated/${user?.id}`, handleBooking);
        socket.off(`vendor/quoteUpdated/${user?.id}`, handleQuote);
        socket.off(`vendor/bookingCommission/${user?.id}`, handleToDecision);
        socket.off(`vendor/bookingDeclined/${user?.id}`, handleToDeclined);
        socket.off(`vendor/bookingAccepted/${user?.id}`, handleToAccepted);

        socket.off(
          `vendor/serviceBooking/inProgress/${user?.id}`,
          handleToInProgress
        );
        socket.off(
          `vendor/serviceBooking/completed/${user?.id}`,
          handleToCompleted
        );

        socket.off(`serviceBooking/dispute/${user?.id}`, handleToDispute);

        socket.off(`serviceBooking/discarded/${user?.id}`, handleDiscarded);

        // socket.off(`vendor/transaction/${user?.id}`, handleTransaction);

        socket.off(
          `serviceBooking/dispute/resolved/${user?.id}`,
          handleDisputeResolved
        );
      }
    };
  }, [
    handleBooking,
    handleQuote,
    handleToDecision,
    handleToDeclined,
    handleToAccepted,
    handleToInProgress,
    handleToCompleted,
    handleToDispute,
    handleDiscarded,
    handleDisputeResolved,
    user?.id,
  ]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 2000);
  }, []);

  return (
    <div className="main-wrapper" id="session">
      <Routes>
        <Route path="/" element={<ProtectedLayout />}>
          <Route index element={<VendorDashboard />} />
          <Route path="/services" element={<VendorServices />} />
          <Route path="/bookings">
            <Route index element={<Bookings />} />
            <Route path=":id" element={<Bookings />} />
            {/* <Route path="waiting" element={<Bookings />} /> */}
            {/* <Route path="pending" element={<Bookings />} /> */}
            {/* <Route path="declined" element={<Bookings />} /> */}
            <Route path="decision" element={<Bookings />}>
              <Route path=":id" element={<Bookings />} />
            </Route>
            <Route path="accepted" element={<Bookings />}>
              <Route path=":id" element={<Bookings />} />
            </Route>
            <Route path="inprogress" element={<Bookings />}>
              <Route path=":id" element={<Bookings />} />
            </Route>
            <Route path="completed" element={<Bookings />}>
              <Route path=":id" element={<Bookings />} />
            </Route>
            <Route path="disputed" element={<Bookings />}>
              <Route path=":id" element={<Bookings />} />
            </Route>
            <Route path=":id" element={<Bookings />} />
            <Route path="*" element={<Navigate to="/bookings" />} />
          </Route>
          <Route path="/earnings" element={<Earnings />} />
          <Route path="/settings" element={<VendorSettings />} />
        </Route>
        <Route path="/login" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<CreatePassword />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
        {/* <Route path="/verify-email" element={<Verification />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
