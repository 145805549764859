import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: {},
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (state, { payload }) => {
      state.notifications = payload || {};
    },
  },
});

export const { setNotifications } = NotificationSlice.actions;
export default NotificationSlice.reducer;
