import { createAsyncThunk } from "@reduxjs/toolkit";
import { asyncAlertError, asyncAlertSuccess } from "store/common/commonThunk";
import { setLoading } from "store/common/commonSlice";
import { resetAuth, setAuth, setUser } from "./authSlice";
import { callApi } from "services/api";

export const asyncVerifyToken = createAsyncThunk(
  "verifyToken",
  async (body, { dispatch }) => {
    const res = await callApi({
      path: "users/validate-resetLink",
      method: "POST",
      axiosSecure: false,
      body,
    });
    if (res.success) {
      dispatch(asyncAlertSuccess(res.message));
      return res;
    } else {
      dispatch(asyncAlertError(res.message));
      return res;
    }
  }
);

export const asyncVerifyEmail = createAsyncThunk(
  "verifyAccount",
  async (token, { dispatch }) => {
    try {
      const res = await callApi({
        path: "auth/verify-account",
        method: "POST",
        body: { token },
        axiosSecure: false,
      });

      if (!res.success) {
        dispatch(asyncAlertError("Token Expired!"));
        return { success: false };
      }

      dispatch(asyncAlertSuccess("Verification Successfull!"));
      return { success: true };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncCreatePassword = createAsyncThunk(
  "createPassword",
  async (body, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      const res = await callApi({
        path: "vendor/reset-password",
        method: "POST",
        axiosSecure: false,
        body,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess("Password Created Successfully"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateToken = createAsyncThunk(
  "accessToken",
  async (_, { dispatch, getState }) => {
    try {
      const res = await callApi({
        path: "auth/accesstoken",
        method: "PUT",
        axiosSecure: false,
        token: getState().auth.refreshToken,
      });

      if (res.success) {
        dispatch(
          setAuth({
            token: res.accessToken,
          })
        );
        return { success: true, token: res.accessToken };
      }

      return res;
    } catch (error) {
      return error;
    }
  }
);

export const asyncRegisterVendor = createAsyncThunk(
  "register",
  async (body, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const res = await callApi({
        path: "vendor/register",
        method: "POST",
        body,
        isFormData: true,
        axiosSecure: false,
      });

      if (res.success) {
        return res;
      }
      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncLoginVendor = createAsyncThunk(
  "login",
  async (body, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      const res = await callApi({
        path: "auth/login",
        method: "POST",
        body,
        axiosSecure: false,
      });

      if (res.data?.role && res.data?.role !== "4") {
        dispatch(asyncAlertError("You are not allowed to login here"));
        return { success: false };
      }

      if (res.success) {
        dispatch(
          setAuth({
            user: res.data,
            token: res.token,
            refreshToken: res.refreshToken,
          })
        );
        dispatch(asyncAlertSuccess("Logged In"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateVendorProfile = createAsyncThunk(
  "update/profile",
  async (body, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: "users/profile/settings",
        method: "PUT",
        isFormData: true,
        body,
        token,
      });

      if (res.success) {
        dispatch(setUser(res.data));
        dispatch(asyncAlertSuccess("Profile Updated Successfully!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncLogOutVendor = createAsyncThunk(
  "logout",
  async (ban = false, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "auth/logout",
        method: "POST",
        token,
      });

      if (res.success) {
        dispatch(resetAuth());
        dispatch(
          asyncAlertSuccess(ban ? "You are banned by admin!" : "Logged Out")
        );
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    }
  }
);
export const asyncForgotPassword = createAsyncThunk(
  "forgot/password",
  async (body, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const res = await callApi({
        path: "vendor/forgot/password",
        method: "POST",
        body,
        axiosSecure: false,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res.message));
        return { success: true };
      }
      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError("Something went wrong, Please try again"));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);
