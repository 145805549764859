import { BrowserRouter } from "react-router-dom";

import App from "App";
import { Provider } from "react-redux";
import { store } from "store";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "store";

function AppRouter() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default AppRouter;
