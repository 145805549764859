import ax from "axios";
import { store } from "store";
import { resetAuth } from "store/auth/authSlice";
import { asyncUpdateToken } from "store/auth/authThunk";
import { asyncAlertError } from "store/common/commonThunk";

export const APP_URL = "https://api.relocates.com.au";
// export const APP_URL = "http://192.168.100.35:3001;
// export const APP_URL = "http://localhost:3002";

export const BASE_URL = `${APP_URL}/api/`;
export const BASE_URL_IMAGE = `${APP_URL}/uploads/`;

const controller = new AbortController();
const signal = controller.signal;

const axios = ax.create({
  baseURL: BASE_URL,
  signal,
  // withCredentials: true,
});

const axiosPrivate = ax.create({
  baseURL: BASE_URL,
  signal,
  // withCredentials: true,
});

axiosPrivate.interceptors.response.use(
  (resp) => resp,
  async function (error) {
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.error?.expire
    ) {
      let res = await store.dispatch(asyncUpdateToken()).unwrap();
      if (res?.success) {
        const config = error.config;
        config.headers["Authorization"] = `Bearer ${res?.token}`;
        return axiosPrivate(config);
      } else {
        store.dispatch(resetAuth());
        if (!res.isCancelled) {
          store.dispatch(
            asyncAlertError("Session Expired, Please Login Again")
          );
          controller.abort();
        }
        return res ?? {};
      }
    } else {
      return error?.response ?? {};
    }
  }
);

export { axios, axiosPrivate };
