import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useState } from "react";

import avatar_02 from "assets/images/profiles/avatar-02.jpg";

import Scrollbars from "react-custom-scrollbars";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "store";
import { resetAuth } from "store/auth/authSlice";

import logo_small_svg from "assets/images/logo/FooterLogoR.png";
import logo_svg from "assets/images/logo/relocatesLogo.png";

const VendorSidebar = () => {
  const { pathname } = useLocation();
  const [menu, setMenu] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const handlesidebar = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.toggle("menu-opened");
    var rootss = document.getElementById("session");
    rootss.classList.toggle("slide-nav");
  };

  return (
    <>
      {/* Sidebar */}
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="100vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-header d-flex align-items-center">
            <div className="sidebar-logo">
              <Link to="/">
                <img src={logo_svg} className="img-fluid logo" alt="" />
              </Link>
              <Link to="/">
                <img
                  src={logo_small_svg}
                  className="img-fluid logo-small"
                  alt=""
                />
              </Link>
            </div>

            <Link
              id="mobile_btn"
              className="me-0"
              to="#;"
              onClick={() => handlesidebar()}
            >
              <i className="fas fa-align-left" />
            </Link>
            {/* <div className="siderbar-toggle">
              <label className="switch" id="toggle_btn">
                <input type="checkbox" onChange={(e) => handleSidebar(e)} />
                <span className="slider round" />
              </label>
            </div> */}
          </div>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li className={pathname === "/" ? "active" : ""}>
                  <Link to="/">
                    <i className="feather-grid">
                      <FeatherIcon icon={"grid"} />
                    </i>{" "}
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className={pathname === "/services" ? "active" : ""}>
                  <Link to="/services">
                    <i className="feather-briefcase">
                      <FeatherIcon icon={"briefcase"} />
                    </i>{" "}
                    <span>My Services</span>
                  </Link>
                </li>
                <li className={pathname === "/bookings" ? "active" : ""}>
                  <Link to="/bookings">
                    <i className="feather-calendar">
                      <FeatherIcon icon={"calendar"} />
                    </i>{" "}
                    <span>Bookings </span>
                  </Link>
                </li>

                <li className={pathname === "/earnings" ? "active" : ""}>
                  <Link to="/earnings">
                    <i className="feather-dollar-sign">
                      <FeatherIcon icon={"dollar-sign"} />
                    </i>{" "}
                    <span>Earnings</span>
                  </Link>
                </li>

                <li className={pathname === "/settings" ? "active" : ""}>
                  <Link
                    to="/settings"
                    // onClick={() => setMenu(true)}
                    // className={true == menu ? "subdrop" : ""}
                  >
                    <i className="feather-settings">
                      <FeatherIcon icon={"settings"} />
                    </i>{" "}
                    <span>Settings</span>
                    {/* <span className="menu-arrow" /> */}
                  </Link>
                  {/* <ul className={true == menu ? "d-block" : ""}>
                    <li
                      className={
                        pathname === "/provider-profile-settings"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/provider-profile-settings">
                        Account Settings
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#del-account"
                      >
                        Delete Account
                      </Link>
                    </li>
                  </ul> */}
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      store.dispatch(resetAuth());
                    }}
                  >
                    <i className="feather-log-out">
                      <FeatherIcon icon={"log-out"} />
                    </i>
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
              {/* <div className="menu-bottom">
                <div className="menu-user">
                  <div className="menu-user-img avatar-online">
                    <img src={avatar_02} alt="user" />
                  </div>
                  <div className="menu-user-info">
                    <h6>{user.name}</h6>
                    <p>{user?.email}</p>
                  </div>
                </div>
                <Link to="#" className="select-set">
                  <i className="feather-settings">
                    <FeatherIcon icon={"settings"} />
                  </i>
                </Link>
                <div className="dropdown-menu user-drop" id="dropboxes">
                  <div className="menu-user">
                    <div className="menu-user-img avatar-online">
                      <img src={avatar_02} alt="user" />
                    </div>
                    <div className="menu-user-info">
                      <h6>John Smith</h6>
                      <p>Active</p>
                    </div>
                  </div>
                  <div className="set-user">
                    <p>Set as Away</p>
                    <div className="status-toggle">
                      <input
                        type="checkbox"
                        id="active-user"
                        className="check"
                      />
                      <label htmlFor="active-user" className="checktoggle">
                        checkbox
                      </label>
                    </div>
                  </div>
                  <ul className="set-menu">
                    <li
                      className={
                        pathname === "/provider-security-settings"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/provider-security-settings">
                        <i className="feather-settings me-2">
                          <FeatherIcon icon={"settings"} />
                        </i>{" "}
                        Settings
                      </Link>
                    </li>
                    <li
                      className={
                        pathname === "/provider-profile-settings"
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/provider-profile-settings">
                        <i className="feather-user me-2">
                          <FeatherIcon icon={"user"} />
                        </i>{" "}
                        Your Account
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="feather-check-circle me-2">
                          <FeatherIcon icon={"check-circle"} />
                        </i>{" "}
                        Identity Verification
                      </Link>
                    </li>
                  </ul>
                  <ul className="help-menu">
                    <li>
                      <Link to="#">Help Center</Link>
                    </li>
                    <li>
                      <Link to="/terms-condition">Terms of Condition</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* /Sidebar */}
    </>
  );
};
export default VendorSidebar;
