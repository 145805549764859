import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "services/api";
import { setTransactions } from "./paymentSlice";

export const asyncGetAllTransactions = createAsyncThunk(
  "payment/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "vendor/payment-history",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setTransactions(res.data));
        return { success: true };
      } else {
        dispatch(setTransactions([]));
      }

      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);
