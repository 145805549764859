import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useCallback, useEffect, useState } from "react";
// import undo_icon from "public/assets/images/icons/undo-icon.svg";
// import google_svg from "assets/images/icons/google.svg";
// import fb_svg from "assets/images/icons/fb.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Form, Modal, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { asyncRegisterVendor } from "store/auth/authThunk";
import { selectToken } from "store/auth/authSlice";
// import SignupHeader from "@/components/SignupHeader";

import ImgCrop from "antd-img-crop";
import SelectBox from "commoncomponent/Select/Select";
import { asyncGetAllActiveServices } from "store/service/serviceThunk";
import { asyncAlertSuccess } from "store/common/commonThunk";
import PhoneInput, { phoneInputValidator } from "commoncomponent/PhoneInput";

const signup = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPass, setShowPass] = useState(false);
  const token = useSelector(selectToken);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const loading = useSelector((state) => state.common.loading);
  let activeServices = useSelector((state) => state.service.activeServices);
  activeServices = Object.values(activeServices ?? {});

  const loadData = useCallback(async () => {
    await dispatch(asyncGetAllActiveServices()).unwrap();
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onFinish = async (values) => {
    let formData = new FormData();
    formData.append("name", values.name);

    formData.append(
      "serviceId",
      JSON.stringify(values.serviceId.map((item) => item.value))
    );

    formData.append("email", values.email);
    formData.append("mobileNumber", values.mobileNumber);

    if (values.password) {
      formData.append("password", values.password);
    }

    if (fileList[0]?.originFileObj) {
      formData.append("file", values.file);
    }

    let res = await dispatch(asyncRegisterVendor(formData)).unwrap();
    if (res.success) {
      dispatch(asyncAlertSuccess(res.message));
      navigate("/login");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    form.setFieldsValue({
      file: newFileList[0]?.originFileObj ?? null,
    });
    form.validateFields(["file"]);
  };

  if (token) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                {/* <div className="login-back">
                  <Link href="/">
                    <Image src={undo_icon} className="me-2" alt="icon" />
                    Back To Home
                  </Link>
                </div> */}
                <div className="login-header">
                  <h3>Signup</h3>
                </div>
                {/* Login Form */}
                <Form
                  form={form}
                  name="signup"
                  initialValues={{
                    name: "",
                    serviceId: [],
                    email: "",
                    mobileNumber: "",
                    password: "",
                    file: null,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <div className="form-group">
                    <Form.Item
                      name="file"
                      validateFirst={true}
                      rules={[
                        {
                          required: true,
                          message: `Image is required!`,
                        },
                      ]}
                    >
                      <ImgCrop accept=".png,.jpeg,.jpg">
                        <Upload
                          multiple={false}
                          listType="picture-card"
                          customRequest={({ onSuccess }) => onSuccess("ok")}
                          fileList={fileList}
                          showUploadList={true}
                          onPreview={handlePreview}
                          onChange={handleChange}
                          maxCount={1}
                        >
                          {fileList.length === 1 ? null : "Upload"}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Name is required!",
                        },
                      ]}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your name"
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>Select Service</label>
                    <Form.Item
                      name="serviceId"
                      rules={[
                        {
                          required: true,
                          message: "Service is required!",
                        },
                      ]}
                    >
                      <SelectBox
                        isMulti={true}
                        options={activeServices.map((service) => ({
                          label: service.name,
                          value: service.id,
                        }))}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">E-mail</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          whitespace: true,
                          message: "Email is required!",
                        },
                      ]}
                    >
                      <input
                        type="email"
                        className="form-control"
                        placeholder="example@email.com"
                      />
                    </Form.Item>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="col-form-label">Phone Number</label>
                      <div className="form-group">
                        <Form.Item
                          name="mobileNumber"
                          validateFirst
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Phone is required!",
                            },
                            phoneInputValidator,
                          ]}
                        >
                          <PhoneInput />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label d-block">Password</label>
                    {/* <span className="brief-bio float-end">
                        Must be 8 Characters at Least
                      </span> */}
                    <div className="pass-group">
                      <Form.Item
                        name="password"
                        validateFirst
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Password is required!",
                          },
                          {
                            min: 8,
                            message: "Must be 8 Characters at Least",
                          },
                        ]}
                      >
                        <span>
                          <input
                            autoComplete="new-password"
                            type={showPass ? "text" : "password"}
                            className="form-control pass-input"
                            placeholder="*************"
                          />
                          <span className="toggle-password feather-eye">
                            <FeatherIcon
                              icon={showPass ? "eye-off" : "eye"}
                              onClick={() => setShowPass((s) => !s)}
                            />
                          </span>
                        </span>
                      </Form.Item>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-6">
                      <label className="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          className="rememberme"
                        />
                        <span className="checkmark" />
                        Remember Me
                      </label>
                    </div>
                    <div className="col-6 text-end">
                      <label className="custom_check">
                        <input
                          type="checkbox"
                          name="loginotp"
                          className="loginotp"
                        />
                        <span className="checkmark" />
                        Login with OTP
                      </label>
                    </div> 
                  </div> */}
                  <button
                    disabled={loading}
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Register Now
                  </button>
                  {/* <div className="login-or">
                    <span className="or-line" />
                    <span className="span-or">Or, log in with your email</span>
                  </div> */}
                  {/* <div className="social-login">
                    <Link href="#" className="btn btn-google w-100">
                      <img src={google_svg} className="me-2" alt="img" />
                      Log in with Google
                    </Link>
                    <Link href="#" className="btn btn-google w-100">
                      <img src={fb_svg} className="me-2" alt="img" />
                      Log in with Facebook
                    </Link>
                  </div> */}
                  <p className="no-acc">
                    Already have an account <Link to="/login">Login</Link>
                  </p>
                </Form>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                    }}
                    src={previewImage}
                  />
                </Modal>
                {/* /Login Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default signup;
