import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useCallback, useEffect, useState } from "react";

import avatar_02 from "assets/images/profiles/avatar-02.jpg";

import us from "assets/images/flags/us.png";

import de from "assets/images/flags/de.png";
import es from "assets/images/flags/es.png";
import fr from "assets/images/flags/fr.png";
import us1 from "assets/images/flags/us1.png";

import avatar_01 from "assets/images/profiles/avatar-01.jpg";
import avatar_03 from "assets/images/profiles/avatar-03.jpg";
import avatar_04 from "assets/images/profiles/avatar-04.jpg";

import logo_small_svg from "assets/images/logo/FooterLogoR.png";
import logo_svg from "assets/images/logo/relocatesLogo.png";

import circle_icon from "assets/images/icons/circle-icon.svg";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth } from "store/auth/authSlice";
import { asyncGetAllNotifications } from "store/notification/notificationThunk";
import { BASE_URL_IMAGE } from "services/axios";
import { formatDate } from "utils";

const VendorHeader = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  let services = useSelector((state) => state.service.services);
  let notifications = useSelector((state) => state.notification.notifications);

  services = Object.values(services ?? {});
  notifications = Object.values(notifications ?? {});

  const [notifcations, setNotifcations] = useState(false);

  const handlesidebar = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.toggle("menu-opened");
    var rootss = document.getElementById("session");
    rootss.classList.toggle("slide-nav");
  };

  // const onHandleMobileMenu = () => {
  //   var root = document.getElementsByTagName("html")[0];
  //   root.classList.add("menu-opened");
  // };

  // const onhandleCloseMenu = () => {
  //   var root = document.getElementsByTagName("html")[0];
  //   root.classList.remove("menu-opened");
  // };

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    };

    const maximizeBtn = document.querySelector(".win-maximize");
    maximizeBtn.addEventListener("click", handleClick);

    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
    };
  }, []);

  const loadData = useCallback(async () => {
    dispatch(asyncGetAllNotifications());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      {/* Header */}
      <div className="header">
        <div className="header-left">
          {/* <div className="sidebar-logo"> */}
          <Link to="/">
            {/* <img src={logo_svg} className="img-fluid logo" alt="" /> */}
          </Link>
          <Link to="/">
            {/* <img src={logo_small_svg} className="img-fluid logo-small" alt="" /> */}
          </Link>
          {/* </div>  */}
          {/* <div className="siderbar-toggle">
            <label className="switch" id="toggle_btn">
              <input type="checkbox" />
              <span className="slider round" />
            </label>
          </div> */}
        </div>
        <Link
          className="mobile_btns"
          id="mobile_btns"
          onClick={() => handlesidebar()}
        >
          <i className="fas fa-align-left" />
        </Link>
        <div className="header-split">
          <div className="page-headers">
            {/* <div className="search-bar">
              <span>
                <i className="feather-search" />
              </span>
              <input
                type="text"
                placeholder="Search"
                className="form-control"
              />
            </div> */}
          </div>
          <ul className="nav user-menu">
            {/* Notifications */}
            {/* <li className="nav-item">
              <Link to="/" className="viewsite">
                <i className="feather-globe me-2" />
                <FeatherIcon icon={"globe"} />
                <i /> View Site
              </Link>
            </li> */}
            {/* <li className="nav-item dropdown has-arrow dropdown-heads flag-nav">
              <Link
                className="nav-link"
                data-bs-toggle="dropdown"
                to="#"
                role="button"
              >
                <img src={us1} alt="" height={20} />
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to="#;" className="dropdown-item">
                  <img src={us} className="me-2" alt="" height={16} /> English
                </Link>
                <Link to="#;" className="dropdown-item">
                  <img src={fr} className="me-2" alt="" height={16} /> French
                </Link>
                <Link to="#;" className="dropdown-item">
                  <img src={es} className="me-2" alt="" height={16} /> Spanish
                </Link>
                <Link to="#;" className="dropdown-item">
                  <img src={de} className="me-2" alt="" height={16} /> German
                </Link>
              </div>
            </li>
            <li className="nav-item  has-arrow dropdown-heads ">
              <Link to="#">
                <i className="feather-moon">
                  <FeatherIcon icon={"moon"} />
                </i>
              </Link>
            </li> */}
            <li className="nav-item dropdown has-arrow dropdown-heads ">
              <Link
                to="#"
                data-bs-toggle="dropdown"
                onClick={() => setNotifcations(!notifcations)}
              >
                <i className="feather-bell">
                  <FeatherIcon icon={"bell"} />
                </i>
              </Link>
              <div
                className={`dropdown-menu notifications notify ${
                  notifcations === true ? "show" : ""
                }`}
              >
                <div className="topnav-dropdown-header">
                  <div>
                    <span className="notification-title">Notifications</span>
                    {/* <select>
                      <option>All</option>
                      <option>Read</option>
                    </select> */}
                  </div>
                  {/* <Link to="#" className="clear-noti">
                    Mark all as read{" "}
                    <i className="fa-regular fa-circle-check" />{" "}
                  </Link> */}
                </div>
                <div className="noti-content">
                  <ul className="notification-list">
                    {!notifications.length ? (
                      <li>
                        <div className="media d-flex">
                          <div className="media-body flex-grow-1 text-center align-items-center">
                            <p className="noti-details">No new notification</p>
                          </div>
                        </div>
                      </li>
                    ) : (
                      notifications?.map((notification) => {
                        return (
                          <li
                            key={notification.id}
                            className="notification-message"
                          >
                            <Link to="/bookings">
                              <div className="media d-flex">
                                {/* <span className="avatar avatar-sm avatar-online flex-shrink-0">
                                  <img
                                    className="avatar-img rounded-circle"
                                    alt="User Image"
                                    src={avatar_04}
                                  />
                                </span> */}
                                <div className="media-body flex-grow-1">
                                  <p className="noti-details">
                                    <span className="noti-title">
                                      {notification?.message}
                                    </span>
                                  </p>
                                  <p className="noti-time">
                                    <span className="notification-time">
                                      {formatDate(notification?.createdAt)}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })
                    )}
                    {/* <li className="notification-message">
                      <div className="media d-flex">
                        <Link to="notification">
                          <span className="avatar avatar-sm avatar-online flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={avatar_02}
                            />
                          </span>
                        </Link>
                        <div className="media-body flex-grow-1">
                          <Link to="notification">
                            <p className="noti-details">
                              Lex Murph{" "}
                              <span className="noti-title">
                                {" "}
                                requested access to
                              </span>{" "}
                              Computer &amp; Server AMC Service{" "}
                            </p>
                          </Link>
                          <div className="notify-btns">
                            <button className="btn btn-primary">Accept</button>
                            <button className="btn btn-secondary">
                              Cancel
                            </button>
                          </div>
                          <p className="noti-time">
                            <span className="notification-time">
                              Today 10:04 PM
                            </span>
                          </p>
                        </div>
                      </div>
                    </li> */}
                    {/* <li className="notification-message">
                      <Link to="provider-notifcations">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm avatar-online flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={avatar_01}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              Ray Arnold{" "}
                              <span className="noti-title">
                                left 6 comments on
                              </span>{" "}
                              Commercial Painting Services
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                Today 9:45 PM
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li> */}
                    {/* <li className="notification-message">
                      <Link to="provider-notifcations">
                        <div className="media d-flex">
                          <span className="avatar avatar-sm avatar-online flex-shrink-0">
                            <img
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src={avatar_03}
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              Dennis Nedry{" "}
                              <span className="noti-title">commented on</span>{" "}
                              Electric Panel Repairing Service{" "}
                            </p>
                            <h6>
                              “Lorem ipsum dolor sit amet, consectetur
                              adipiscing elit, sed do eiusmod tempor incididunt
                              ut labore et commodo consequat..”
                            </h6>
                            <p className="noti-time">
                              <span className="notification-time">
                                Yesterday 8:17 AM
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="topnav-dropdown-footer">
                  {/* <Link to="provider-notifcations">
                    View More <img src={circle_icon} alt="" />
                  </Link> */}
                </div>
              </div>
            </li>
            <li className="nav-item  has-arrow dropdown-heads ">
              <Link to="#" className="win-maximize maximize-icon">
                <i>
                  <FeatherIcon icon="maximize" />
                </i>
              </Link>
            </li>

            {/* User Menu */}
            <li className="nav-item dropdown has-arrow account-item">
              <Link
                to="#"
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
              >
                <div className="user-infos">
                  <span className="user-img">
                    <img
                      src={
                        user?.image ? BASE_URL_IMAGE + user?.image : avatar_02
                      }
                      className="rounded-circle"
                      alt=""
                    />
                  </span>
                  <div className="user-info">
                    <h6 className="mb-0">{user?.name}</h6>
                  </div>
                </div>
              </Link>
              <div className="dropdown-menu dropdown-menu-end emp">
                <Link className="dropdown-item" to="/settings">
                  <i className="feather-user me-2">
                    <FeatherIcon icon={"user"} />
                  </i>{" "}
                  Profile
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={() => dispatch(resetAuth())}
                >
                  <i className="feather-log-out me-2">
                    <FeatherIcon icon={"log-out"} />
                  </i>{" "}
                  Log Out
                </Link>
              </div>
            </li>
            {/* /User Menu */}
          </ul>
        </div>
      </div>
      {/* /Header */}
    </>
  );
};
export default VendorHeader;
