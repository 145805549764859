import { createAsyncThunk } from "@reduxjs/toolkit";
import { asyncAlertError, asyncAlertSuccess } from "store/common/commonThunk";
import { setLoading } from "store/common/commonSlice";
import { callApi } from "services/api";
import { setBookings } from "./bookingSlice";
import { bookingStatuses } from "utils";

export const asyncGetAllVendorBookingsRequest = createAsyncThunk(
  "booking/list",
  async (_, { dispatch, getState }) => {
    try {
      
      const token = getState().auth.token;

      const res = await callApi({
        path: "vendor/bookings",
        method: "GET",
        token,
      });

      let dataSource = {};
      if (res.success) {
        dataSource = res.data.reduce((acc, curr) => {
          let status = curr.disputeReason ? 18 : curr.status;
          status = bookingStatuses?.[status]?.key;

          return {
            ...acc,
            [status]: { ...(acc[status] || {}), ["booking_" + curr.id]: curr },
          };
        }, {});

        dispatch(setBookings({ ...dataSource }));
        return { success: true };
      }

      dispatch(setBookings({}));
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncUpdateQuoteAmount = createAsyncThunk(
  "booking/update",
  async ({ quoteId, ...body }, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `vendor/quotes/${quoteId}`,
        method: "PUT",
        token,
        body,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res.message));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateBookingToInProgress = createAsyncThunk(
  "booking/inprogress",
  async (serviceBookingId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `vendor/bookings-inprogress/${serviceBookingId}`,
        method: "PUT",
        token,
        body: { status: 5 },
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res.message));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const asyncUpdateBookingToCompleted = createAsyncThunk(
  "booking/update",
  async (serviceBookingId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;

      const res = await callApi({
        path: `vendor/bookings-completed/${serviceBookingId}`,
        method: "PUT",
        token,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess(res.message));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);
