import service_04 from "assets/images/services/service-04.jpg";
import styles from "./style.module.css";
import { BASE_URL_IMAGE } from "services/axios";
import { Form, Input } from "antd";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  asyncUpdateBookingToCompleted,
  asyncUpdateBookingToInProgress,
  asyncUpdateQuoteAmount,
} from "store/booking/bookingThunk";
import {
  bookingStatuses,
  formatDate,
  statusBadges,
  toNumber,
  validateDecimalsPrecision,
} from "utils";
import { formatPhoneNumber } from "react-phone-number-input";

// const ParamElement = ({ param }) => {
//   return (
//     <div className="col-4">
//       <h2>{param?.serviceParameter?.label}</h2>
//       <p className={styles.desc}>{JSON.parse(param?.value)}</p>
//     </div>
//   );
// };

const ServiceCard = ({
  managedParams: details = {},
  booking,
  serviceBooking,
  serviceBookingId,
  status,
  service,
  customer,
  quote,
}) => {
  let dispatch = useDispatch();

  const onFinish = useCallback(async (values) => {
    await dispatch(
      asyncUpdateQuoteAmount({
        quoteId: quote?.id,
        serviceId: service?.id,
        ...values,
      })
    ).unwrap();
  }, []);

  const handleStartJob = useCallback(async () => {
    await dispatch(asyncUpdateBookingToInProgress(serviceBookingId)).unwrap();
  }, [serviceBookingId]);

  const handleCompleteJob = useCallback(async () => {
    await dispatch(asyncUpdateBookingToCompleted(serviceBookingId)).unwrap();
  }, [serviceBookingId]);

  return (
    <div className={`${styles.carWash} ${"service-widget servicecontent"}`}>
      <div>
        <div className="service-img">
          <img
            className="img-fluid serv-img"
            alt="Service Image"
            src={
              service?.parentService?.image
                ? BASE_URL_IMAGE + service?.parentService?.image
                : service_04
            }
          />
          <div className="fav-item">
            <span className="item-cat">{service?.parentService?.name}</span>
            {/* <span className="item-cat">
              <i className="fas fa-star filled me-1" />
              4.9
            </span> */}
          </div>
        </div>
        <div className={styles.content}>
          <span className={`${statusBadges[status]} text-center mb-2 d-block`}>
            {`${bookingStatuses?.[status]?.value}`}
          </span>
          <h1>
            #{serviceBookingId} -{" "}
            {service?.name
              ?.split(" ")
              ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
              ?.join(" ")}
          </h1>
          <h2>Description</h2>
          <p className={styles.desc}>
            {booking?.description ?? "No description provided"}
          </p>

          <p className="fw-bold mb-0">
            {serviceBooking?.to && serviceBooking?.from ? (
              <>
                <span className="me-5">To: {serviceBooking?.to}</span>
                <span>From: {serviceBooking?.from}</span>
              </>
            ) : (
              <span>Location: {booking?.address}</span>
            )}
          </p>

          <div className="row">
            {Object.values(details ?? {}).map((param) => {
              let data = param?.rows ?? [param];

              return (
                <div key={param.id} className="pt-2">
                  <div className="border border-gray-300 px-3 py-2 rounded-2">
                    {param.groupHeading && (
                      <p className="fw-bold fs-5 mb-2">{param.groupHeading}</p>
                    )}
                    <div>
                      {data?.map((row) => {
                        let value = row?.value;

                        if (typeof value === "object") {
                          if (!Array.isArray(value)) {
                            value = [value];
                          }

                          value = value.reduce((acc, { label, value }) => {
                            const tempValue =
                              row?.type === "params"
                                ? `${label}(${value})`
                                : value;
                            return acc === ""
                              ? tempValue
                              : `${acc} | ${tempValue}`;
                          }, "");
                        }

                        return (
                          <div key={row?.id}>
                            <p className="mb-0">{row?.label}</p>
                            <p className="fw-bold mb-0">
                              <span>{value}</span>
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.content}>
          <h1>Order Details</h1>
          <h2 className="mt-3">Customer Name</h2>
          <p className={styles.desc}>{customer?.name}</p>
          <h2 className="mt-4">Customer Address</h2>
          <p className={styles.desc}>
            {booking?.address ?? "No address provided"}
          </p>
          <div className="row">
            {quote?.id && toNumber(quote?.quoteAmount) > 0 && status > 2 && (
              <div className="col-6">
                <h2>Phone Number</h2>
                <p className={styles.desc}>
                  {formatPhoneNumber(customer?.mobileNumber) || "N/A"}
                </p>
              </div>
            )}
            <div className="col-6">
              <h2>Job Date</h2>
              <p className={styles.desc}>{formatDate(booking?.bookingDate)}</p>
            </div>
            <div className="col-6">
              <h2>Job Time</h2>
              <p className={styles.desc}>{booking?.bookingTime}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        {quote?.id && toNumber(quote?.quoteAmount) > 0 ? (
          <>
            <div>
              <h3>Quote Description</h3>
              <p className={styles.desc}>
                {quote?.quoteDescription || "No quote description provided"}
              </p>
            </div>
            {[3, 5].includes(status) ? (
              <button
                onClick={status === 3 ? handleStartJob : handleCompleteJob}
                className="btn btn-primary w-100"
              >
                {status === 3 ? "START JOB" : "MARK JOB COMPLETED"}
              </button>
            ) : null}

            <div className={styles.qouteWrapper}>
              {status <= 2
                ? "Provided Quote"
                : status <= 5
                ? "Accepted Quote"
                : "Job Completed"}
              <b>${quote?.quoteAmount}</b>
            </div>
          </>
        ) : (
          <Form name="quoteDetails" onFinish={onFinish}>
            <div className="form-group">
              <label>Quote Description</label>
              <Form.Item
                name="quoteDescription"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please provide quote description!",
                  },
                  {
                    max: 250,
                    message: "Quote description should be less than 250!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </div>
            <div>
              <label>Quote Amount</label>
              <Form.Item
                name="quoteAmount"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Please provide your quote amount!",
                  },
                  validateDecimalsPrecision,
                ]}
              >
                <input
                  type="number"
                  step="0.01"
                  className="form-control"
                  placeholder="$0.00"
                />
              </Form.Item>
            </div>

            <button type="submit" className="btn btn-primary w-100">
              Send Quote
            </button>
          </Form>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
