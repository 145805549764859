// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*---------------///---------------*/
/*         Car Wash Card           */
.style_carWash__VggyM {
  background-color: transparent;
  border: none;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.style_carWash__VggyM:hover {
  box-shadow: none;
}
.style_carWash__VggyM .style_content__8U7ZI {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.style_carWash__VggyM .style_content__8U7ZI h1 {
  font-size: 24px;
  font-weight: 600;
}
.style_carWash__VggyM .style_content__8U7ZI h2 {
  font-size: 12px;
  font-weight: 500;
  color: #74788d;
  margin-bottom: 2px;
}
.style_carWash__VggyM .style_content__8U7ZI .style_desc__Tqap9 {
  color: #2a313d;
  line-height: 19px;
  width: 80%;
}
.style_carWash__VggyM .style_content__8U7ZI h3 {
  color: #2a313d;
  line-height: 19px;
  width: 80%;
}

.style_qouteWrapper__PW2Th {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  line-height: 24px;
  color: black;
  margin-top: 40px;
}
.style_qouteWrapper__PW2Th b {
  margin-left: 20px;
  font-size: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Cards/style.module.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC,oCAAoC;AACpC;EACE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,UAAU;AACZ;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":["/*---------------///---------------*/\n/*         Car Wash Card           */\n.carWash {\n  background-color: transparent;\n  border: none;\n  height: 100%;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n.carWash:hover {\n  box-shadow: none;\n}\n.carWash .content {\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}\n.carWash .content h1 {\n  font-size: 24px;\n  font-weight: 600;\n}\n.carWash .content h2 {\n  font-size: 12px;\n  font-weight: 500;\n  color: #74788d;\n  margin-bottom: 2px;\n}\n.carWash .content .desc {\n  color: #2a313d;\n  line-height: 19px;\n  width: 80%;\n}\n.carWash .content h3 {\n  color: #2a313d;\n  line-height: 19px;\n  width: 80%;\n}\n\n.qouteWrapper {\n  display: flex;\n  justify-content: flex-end;\n  font-size: 20px;\n  line-height: 24px;\n  color: black;\n  margin-top: 40px;\n}\n.qouteWrapper b {\n  margin-left: 20px;\n  font-size: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carWash": `style_carWash__VggyM`,
	"content": `style_content__8U7ZI`,
	"desc": `style_desc__Tqap9`,
	"qouteWrapper": `style_qouteWrapper__PW2Th`
};
export default ___CSS_LOADER_EXPORT___;
