import { axios, axiosPrivate } from "./axios";
import { responseCallback } from "./responseCallback";

export const callApi = ({
  path = "",
  method = "GET",
  body = null,
  token = null,
  isFormData = false,
  axiosSecure = true,
  options: ops = {},
}) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (isFormData) {
    headers = {};
  }

  let options = {
    method,
    ...ops,
  };

  if (token) headers["authorization"] = "Bearer " + token;

  options.headers = headers;

  if (body) options.data = body;

  options.url = path;

  let axiosInstance = axiosSecure ? axiosPrivate : axios;

  return axiosInstance(options)
    .then(responseCallback)
    .catch((err) => {
      return responseCallback(err.response ?? err);
    });
};
