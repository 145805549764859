import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useCallback, useEffect, useMemo } from "react";

import bel_icon from "assets/images/icons/bal-icon.svg";
import { Link } from "react-router-dom";
import { asyncGetAllTransactions } from "store/payment/paymentThunk";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { formatDate } from "utils";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";

const Earnings = () => {
  const [transactionLoader, fetchTransactions] = useLoaderDispatch(
    asyncGetAllTransactions
  );

  let transactions = useSelector((state) => state.payment.payments);
  transactions = useMemo(
    () => Object.values(transactions ?? {}),
    [transactions]
  );

  const totalEarn = useSelector((state) => state.payment.totalEarn);
  const totalPending = useSelector((state) => state.payment.totalPending);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  // const dispatch = useDispatch();

  // const loadData = useCallback(async () => {
  //   await dispatch(asyncGetAllTransactions()).unwrap();
  // }, [dispatch]);

  // useEffect(() => {
  //   loadData();
  // }, [loadData]);

  useEffect(() => {
    document.body.classList.add("provider-body");

    return () => document.body.classList.remove("provider-body");
  }, []);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "booking Id",
      dataIndex: "bookingId",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Payout Date",
      dataIndex: "updatedAt",
      render: (updatedAt) => <span>{formatDate(updatedAt)}</span>,
    },
    {
      title: "Amount",
      dataIndex: "quoteAmount",
      render: (quoteAmount) => <span>${quoteAmount}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => {
        let isComplete = +record?.booking?.booking?.isCompleted;
        return (
          <span
            className={isComplete ? "badge-success" : "badge badge-warning"}
          >
            {isComplete ? "Paid" : "Pending"}
          </span>
        );
      },
    },
    // {
    //   title: "Payment Processed",
    //   dataIndex: "address",
    //   render: (_, record) => <span>{record?.booking?.address}</span>,
    // },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: (_, record) => (
    //     <div className="table-actions d-flex">
    //       {/* <Link
    //         className="delete-table me-2"
    //         to="#"
    //         state={{ service: record }}
    //       >
    //         <img src={edit} alt="svg" />
    //       </Link>
    //       <Link
    //         to="#"
    //         className="delete-table me-2"
    //         data-bs-toggle="modal"
    //         data-bs-target="#delete-item"
    //         onClick={(e) => {
    //           e.preventDefault();
    //           // setServiceId(record?.id);
    //         }}
    //       >
    //         <img src={Delete} alt="svg" />
    //       </Link> */}
    //       <Link
    //         className="delete-table"
    //         role="button"
    //         // to="#offcanvasRight"
    //         // data-bs-toggle="offcanvas"
    //         // aria-controls="offcanvasRight"
    //       >
    //         <img src={eye} alt="svg" />
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid px-3 px-lg-4">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>Payout</h6>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            {/* Payout card */}
            <div className="col-12 col-sm-6 col-md-5 col-xl-4 col-xxl-3 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="balance-crad">
                    <div className="balance-head">
                      <span className="balance-icon">
                        <img src={bel_icon} alt="" />
                      </span>
                      <div className="balance-info">
                        <h6>Total Earn</h6>
                        <h3>
                          $
                          <span className="counters" data-count={180}>
                            {transactionLoader ? "Loading..." : totalEarn}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-5 col-xl-4 col-xxl-3 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="balance-crad">
                    <div className="balance-head">
                      <span className="balance-icon">
                        <img src={bel_icon} alt="" />
                      </span>
                      <div className="balance-info">
                        <h6>Pending Payments</h6>
                        <h3>
                          $
                          <span className="counters" data-count={200}>
                            {transactionLoader ? "Loading..." : totalPending}
                          </span>
                        </h3>
                      </div>
                    </div>
                    {/* <div className="balance-amt">
                      <Link
                        href="#"
                        className="btn btn-secondary view-transaction"
                      >
                        View Transactions
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* /Payout card */}
          </div>
          {/* Payout History */}
          <div className="row">
            <div className="col-md-12">
              <h6 className="subhead-title">Payout History</h6>
              <div className="provide-table manage-table">
                <div className="table-responsive">
                  <div className="table datatable">
                    <Table
                      columns={columns}
                      dataSource={transactions}
                      loading={transactionLoader}
                      rowKey={(record) => record.id}
                      showSizeChanger={true}
                      pagination={{
                        total: transactions?.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                      }}
                    />
                  </div>
                  {/* <table
                    className="table custom-table datatable"
                    id="data-table"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Payout Date</th>
                        <th>Amount</th>
                        <th>Refunds</th>
                        <th>Fees</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Payment Processed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Sep 26, 2022</td>
                        <td>$100.00</td>
                        <td className="text-light-danger">-$10.00</td>
                        <td className="text-light-danger">-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge badge-warning">Pending</span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td>Sep 21, 2022</td>
                        <td>$100.00</td>
                        <td>-$0.00</td>
                        <td className="text-light-danger">-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge-success">Paid</span>
                        </td>
                        <td>Sep 11, 2022</td>
                      </tr>
                      <tr>
                        <td>Sep 21, 2022</td>
                        <td>$100.00</td>
                        <td className="text-light-danger">-$0.00</td>
                        <td>-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge-success">Paid</span>
                        </td>
                        <td>Sep 11, 2022</td>
                      </tr>
                      <tr>
                        <td>Sep 21, 2022</td>
                        <td>$100.00</td>
                        <td>-$0.00</td>
                        <td>-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge-success">Paid</span>
                        </td>
                        <td>Sep 11, 2022</td>
                      </tr>
                      <tr>
                        <td>Sep 21, 2022</td>
                        <td>$100.00</td>
                        <td>-$0.00</td>
                        <td>-$10.00</td>
                        <td>$90.00</td>
                        <td>
                          <span className="badge-success">Paid</span>
                        </td>
                        <td>Sep 11, 2022</td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div id="tablelength" />
                </div>
                <div className="col-md-9">
                  <div className="table-ingopage">
                    <div id="tableinfo" />
                    <div id="tablepagination" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Payout History */}
        </div>
      </div>
    </>
  );
};
export default Earnings;
