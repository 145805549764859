import { Button, Drawer, Space, Table } from "antd";
import ServiceCard from "components/Cards/ServiceCard";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { asyncGetAllVendorBookingsRequest } from "store/booking/bookingThunk";
import {
  bookingStatuses,
  formatDate,
  statusBadges,
  getColumnSearchProps,
} from "utils";

import eye from "assets/images/icons/eye.svg";
import classNames from "classnames";
import { setSelectedBooking } from "store/booking/bookingSlice";
import styles from "./styles.module.css";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";

// const tabs = {
//   quot: "quotation",
//   assign: "assigned",
// payment: "payment",
// };

const Bookings = () => {
  // const [tab, setTab] = useState(tabs.quot);
  // const [bookingId, setBookingId] = useState(null);
  const [bookingsLoader, fetchAllBookings] = useLoaderDispatch(
    asyncGetAllVendorBookingsRequest
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  let splittedPathName = location.pathname.split("/").filter((x) => x);
  let baseRoute = splittedPathName.slice(0, -1).join("/");
  let route = params?.id ? splittedPathName.at(-2) : splittedPathName.at(-1);

  let bookings = useSelector((state) => {
    if (route) {
      return state.booking?.[route];
    }
    return [];
  });

  // let selectedBooking = useMemo(() => {
  //   return bookings?.["booking_" + se];
  // }, [bookings, bookingId]);

  const bookingByRouteId = bookings?.["booking_" + params?.id];
  const selectedBooking = useSelector((state) => state.booking.selected);

  bookings = useMemo(() => {
    return Object.values(bookings ?? {});
  }, [bookings]);

  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);

  // const getActive = useCallback(
  //   (tabName) => {
  //     if (tabName === tab) return "active";
  //     return "";
  //   },
  //   [tab]
  // );

  useEffect(() => {
    fetchAllBookings();
    return () => {
      if (!params.id && !bookingByRouteId?.id) {
        dispatch(setSelectedBooking({}));
      }
    };
  }, [fetchAllBookings, route]);

  useEffect(() => {
    if (params?.id && bookings?.length) {
      if (bookingByRouteId?.id) {
        dispatch(setSelectedBooking(bookingByRouteId));
        return navigate("/" + baseRoute);
      }
      dispatch(setSelectedBooking({}));
      navigate("/" + baseRoute);
    }
  }, [params?.id, bookings?.length]);

  // const loadData = useCallback(async () => {
  //   await dispatch(asyncGetAllVendorBookingsRequest()).unwrap();
  // }, [dispatch]);

  // useEffect(() => {
  //   loadData();
  // }, [loadData, route]);

  const handleHide = () => {
    dispatch(setSelectedBooking({}));
  };

  const searchInput = useRef(null);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (id) => <span>{id}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Service",
      dataIndex: "name",
      render: (_, record) => <span>{record?.service?.name}</span>,
      ...getColumnSearchProps(searchInput, "service", "name"),
    },
    {
      title: "Quoted Price",
      dataIndex: "quoteAmount",
      render: (_, record) => <span>${record?.quote?.quoteAmount || 0}</span>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (createdAt) => <span>{formatDate(createdAt)}</span>,
    },
    // {
    //   title: "Delivery Location",
    //   dataIndex: "address",
    //   render: (_, record) => <span>{record?.booking?.address}</span>,
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: (status, record) => {
        status = record?.disputeReason ? 18 : status;
        let statusBadge = statusBadges[status];

        return (
          <span className={statusBadge}>
            {bookingStatuses?.[status]?.value}
          </span>
        );
      },
      // sorter: (a, b) => +a.booking?.status - +b.booking?.status,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div className="table-actions d-flex">
          {/* <Link
            className="delete-table me-2"
            to="#"
            state={{ service: record }}
          >
            <img src={edit} alt="svg" />
          </Link>
          <Link
            to="#"
            className="delete-table me-2"
            data-bs-toggle="modal"
            data-bs-target="#delete-item"
            onClick={(e) => {
              e.preventDefault();
              // setServiceId(record?.id);
            }}
          >
            <img src={Delete} alt="svg" />
          </Link> */}
          <Link
            to="#"
            className="delete-table"
            role="button"
            // to="#offcanvasRight"
            // data-bs-toggle="offcanvas"
            // aria-controls="offcanvasRight"
            onClick={() => {
              dispatch(setSelectedBooking(record));
            }}
          >
            <img src={eye} alt="svg" />
          </Link>
        </div>
      ),
    },
  ];

  let managedParams = useMemo(
    () =>
      selectedBooking?.serviceBookingParams?.reduce((acc, curr, index) => {
        let groupHeading = curr?.serviceParameter?.groupHeading ?? null;
        let key = groupHeading ?? index;

        let value = null;
        try {
          value = JSON.parse(curr?.value);
        } catch (e) {
          value = null;
        }

        let objPair = {
          label: curr?.serviceParameter?.label,
          type: curr?.serviceParameter?.type,
          value,
        };

        return {
          ...acc,
          [key]: {
            groupHeading,
            ...(groupHeading
              ? {
                  rows:
                    acc[groupHeading]?.rows.length > 0
                      ? [...acc[groupHeading].rows, objPair]
                      : [objPair],
                }
              : objPair),
          },
        };
      }, {}),
    [selectedBooking.id]
  );

  let isDisputed = selectedBooking?.disputeReason ? true : false;

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid px-3 px-lg-4">
          {/* Page Header */}
          <div className="page-header">
            {/* <!-- Sort --> */}
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="widget-title">
                  <h4>Booking List</h4>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /Sort --> */}
          {/* Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="text-tab-list">
                <ul className={classNames("nav", styles.bookingTabs)}>
                  {Object.values(bookingStatuses ?? {}).map((tab) => {
                    return (
                      <li key={tab.key} className={styles.tab}>
                        <Link
                          to={tab.path}
                          className={classNames(
                            location.pathname === tab.path ? "active" : "",
                            styles.tabLink
                          )}
                        >
                          {tab.value}
                        </Link>
                      </li>
                    );
                  })}
                  {/* <li>
                    <Link
                      to="#"
                      className={getActive(tabs.quot)}
                      onClick={() => setTab(tabs.quot)}
                    >
                      Quotation
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={getActive(tabs.assign)}
                      onClick={() => setTab(tabs.assign)}
                    >
                      Assigned
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={getActive(tabs.payment)}
                      onClick={() => setTab(tabs.payment)}
                    >
                      Payment
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="tab-contentt">
            <div
              className={
                `tab-panee`
                // { tab-pane ${getActive(tabs.quot)} `}
              }
            >
              <div className="table datatable">
                <Table
                  scroll={{
                    x: 1140,
                  }}
                  columns={columns}
                  dataSource={bookings}
                  loading={bookingsLoader}
                  rowKey={(record) => record.id}
                  showSizeChanger={true}
                  pagination={{
                    total: bookings?.length,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                  }}
                />
              </div>
              {/* <div className="table-responsive">
                <table className="table mb-0 custom-table">
                  <thead className="thead-light">
                    <tr>
                      <th>Service</th>
                      <th>Date</th>
                      <th>Delivery Location</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.length > 0 &&
                      bookings.map((booking) => {
                        return (
                          <tr key={booking.id}>
                            <td>{booking?.service?.name}</td>
                            <td className="text-body">
                              {formatDate(booking?.createdAt)}
                            </td>
                            <td className="text-body">
                              {booking?.booking?.address}
                            </td>
                            <td>
                              <span className="badge-success">
                                {serviceStatuses[booking?.status?.toString()]}
                              </span>
                            </td>
                            <td>
                              <a
                                href="#offcanvasRight"
                                role="button"
                                className="btn btn-secondary"
                                data-bs-toggle="offcanvas"
                                aria-controls="offcanvasRight"
                              >
                                View details
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div> */}
            </div>
            {/* <div className={`tab-pane ${getActive(tabs.assign)}`}>
              <div className="table datatable">
                <Table
                  columns={columns}
                  dataSource={bookings}
                  loading={bookings?.length === 0 && loading}
                  rowKey={(record) => record.id}
                  showSizeChanger={true}
                  pagination={{
                    total: bookings?.length,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                  }}
                />
              </div>
              <div className="table-responsive">
                <table className="table mb-0 custom-table">
                  <thead className="thead-light">
                    <tr>
                      <th>Title</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Address</th>
                      <th>Services</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Wallet Topup</td>
                      <td className="text-light-success">+$80</td>
                      <td className="text-body">07 Oct 2022 11:22:51</td>
                      <td className="text-body">Address</td>
                      <td className="text-body">Paypal</td>
                      <td>
                        <span className="badge-success">Completed</span>
                      </td>
                      <td>
                        <a
                          href="#offcanvasRight"
                          role="button"
                          className="btn btn-secondary"
                          data-bs-toggle="offcanvas"
                          aria-controls="offcanvasRight"
                        >
                          View details
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
            {/* <div className={`tab-pane ${getActive(tabs.payment)}`}>
              <div className="table datatable">
                <Table
                  columns={columns}
                  dataSource={bookings}
                  loading={bookings?.length === 0 && loading}
                  rowKey={(record) => record.id}
                  showSizeChanger={true}
                  pagination={{
                    total: bookings?.length,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                  }}
                />
              </div>
              <div className="table-responsive">
                <table className="table mb-0 custom-table">
                  <thead className="thead-light">
                    <tr>
                      <th>Payment Date</th>
                      <th>Release Amount</th>
                      <th>Amount</th>
                      <th>Snapshot</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> Sep 26,2022</td>
                      <td className="text-body">20% of Total $130</td>
                      <td className="text-light-success">$29.00</td>
                      <td className="text-body">Invoice 08 sep,2022</td>
                      <td>
                        <span className="badge-success">Paid</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* OffCanvas */}
      {/* <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-body">
          <ServiceCard
            isQuote={location.pathname !== "/bookings"}
            data={drawerData?.[bookingId] ?? []}
          />
        </div>
      </div> */}

      <Drawer
        placement="right"
        closeIcon={""}
        zIndex={9999}
        size={"small"}
        open={!!selectedBooking.id}
        getContainer={false}
        bodyStyle={{
          marginTop: 10,
          marginBottom: 10,
          scroll: "hidden",
        }}
        extra={
          <Space>
            <Button onClick={handleHide}>Close</Button>
          </Space>
        }
      >
        {selectedBooking.id && (
          <ServiceCard
            serviceBooking={selectedBooking}
            booking={selectedBooking.booking}
            serviceBookingId={selectedBooking.id}
            status={isDisputed ? 18 : +selectedBooking?.status}
            service={selectedBooking?.service}
            customer={selectedBooking?.booking?.user}
            managedParams={managedParams}
            quote={selectedBooking?.quote}
            isQuoteTab={location.pathname !== "/bookings"}
          />
        )}
      </Drawer>
    </>
  );
};
export default Bookings;
