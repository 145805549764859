import { store } from "../store";
import { asyncAlertError } from "store/common/commonThunk";

export const responseCallback = (res) => {
  /*
  {
    ...
    config: {}
    data: {}
    status: 200
    statusText: "OK"
    ...
  }
  data: will be the response return by developer
  status: it is an axios variable
  statusText: it is an axios variable 
  */

  if (res?.name === "CanceledError") {
    return {
      success: false,
      message: "Request Canceled",
      isCancelled: true,
      data: [],
    };
  }

  const message = res?.data?.message ?? res?.statusText;
  const status = res?.status ?? res?.error?.statusCode;

  if (status === 401) {
    if (res?.error?.expire) {
      store.dispatch(
        asyncAlertError("Token Expired or Tempered Please ReAuthenticate - 401")
      );
    }
  }
  if ([400, 401, 404, 409, 500].includes(status)) {
    return {
      success: false,
      data: res?.data,
      status,
      message,
    };
  }
  return res?.data;
};
