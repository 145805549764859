import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { bookingStatuses } from "utils";

const initialState = {
  bookings: {},
  // pending: {},
  decision: {},
  accepted: {},
  // declined: {},
  inprogress: {},
  completed: {},
  disputed: {},
  selected: {},
};

const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setSelectedBooking: (state, { payload }) => {
      state.selected = payload;
    },
    setBookings: (state, { payload }) => {
      state.bookings = payload.bookings || {};
      // state.pending = payload.pending || {};
      state.decision = payload.decision || {};
      state.accepted = payload.accepted || {};
      // state.declined = payload.declined || {};
      state.inprogress = payload.inprogress || {};
      state.completed = payload.completed || {};
      state.disputed = payload.disputed || {};
    },
    setBooking: (state, { payload }) => {
      if (!state.bookings["booking_" + payload.id]) {
        state.bookings = {
          ["booking_" + payload.id]: payload,
          ...state.bookings,
        };
      } else {
        state.bookings["booking_" + payload.id] = payload;
      }
    },
    setQuote: (state, { payload }) => {
      let { booking, ...record } = payload;
      let bookingId = booking.id;

      state[bookingStatuses[booking?.status].key]["booking_" + bookingId][
        "quote"
      ] = record;

      if (state.selected.id === bookingId) {
        state.selected.quote = record;
      }
    },
    bookingsToDecision: (state, { payload }) => {
      payload.forEach(({ bookingServiceId, isRejected }) => {
        if (state.bookings["booking_" + bookingServiceId]) {
          if (!isRejected) {
            state.decision = {
              ["booking_" + bookingServiceId]:
                state.bookings["booking_" + bookingServiceId],
              ...state.decision,
            };
            state.decision["booking_" + bookingServiceId].status = 2;
            state.decision["booking_" + bookingServiceId].booking.status = 2;

            if (state.selected.id === bookingServiceId) {
              state.selected.status = 2;
              state.selected.booking.status = 2;
            }
          } else {
            if (state.selected.id === bookingServiceId) {
              state.selected = {};
            }
          }
          delete state.bookings["booking_" + bookingServiceId];
        }
      });
    },
    bookingsToDecline: (state, { payload }) => {
      payload.bookingServices.forEach((bookingServiceId) => {
        if (state.decision["booking_" + bookingServiceId]) {
          if (state.selected.id === bookingServiceId) {
            state.selected = {};
          }

          delete state.decision["booking_" + bookingServiceId];
        }
      });
    },
    bookingsToAccepted: (state, { payload }) => {
      payload.bookingServices.forEach((bookingServiceId) => {
        if (state.decision["booking_" + bookingServiceId]) {
          state.accepted = {
            ["booking_" + bookingServiceId]:
              state.decision["booking_" + bookingServiceId],
            ...state.accepted,
          };
          state.accepted["booking_" + bookingServiceId].status = 3;
          state.accepted["booking_" + bookingServiceId].booking.status = 5;

          if (state.selected.id === bookingServiceId) {
            state.selected.status = 3;
            state.selected.booking.status = 5;
          }

          delete state.decision["booking_" + bookingServiceId];
        }
      });
    },
    bookingToInProgress: (state, { payload }) => {
      if (state.accepted["booking_" + payload.id]) {
        state.inprogress = {
          ["booking_" + payload.id]: state.accepted["booking_" + payload.id],
          ...state.inprogress,
        };
        state.inprogress["booking_" + payload.id].status = 5;

        if (state.selected.id === payload.id) {
          state.selected.status = 5;
        }

        delete state.accepted["booking_" + payload.id];
      }
    },
    bookingToCompleted: (state, { payload }) => {
      if (state.inprogress["booking_" + payload.id]) {
        state.completed = {
          ["booking_" + payload.id]: state.inprogress["booking_" + payload.id],
          ...state.completed,
        };
        state.completed["booking_" + payload.id].status = 6;

        if (state.selected.id === payload.id) {
          state.selected.status = 6;
        }

        delete state.inprogress["booking_" + payload.id];
      }
    },
    bookingToDisputed: (state, { payload }) => {
      if (state.completed["booking_" + payload.id]) {
        state.disputed = {
          ["booking_" + payload.id]: state.completed["booking_" + payload.id],
          ...state.disputed,
        };

        state.disputed["booking_" + payload.id].disputeReason =
          payload.disputeReason;

        if (state.selected.id === payload.id) {
          state.selected.disputeReason = payload.disputeReason;
        }

        delete state.completed["booking_" + payload.id];
      }
    },
    bookingDiscarded: (state, { payload }) => {
      let { serviceBookingId } = payload;

      if (state.bookings["booking_" + serviceBookingId]) {
        if (state.selected.id === serviceBookingId) {
          state.selected = {};
        }

        delete state.bookings["booking_" + serviceBookingId];
      }
    },
    bookingDisputeResolved: (state, { payload }) => {
      let { serviceBookingId } = payload;

      if (state.disputed["booking_" + serviceBookingId]) {
        state.completed = {
          ["booking_" + serviceBookingId]:
            state.disputed["booking_" + serviceBookingId],
          ...state.completed,
        };

        state.completed["booking_" + serviceBookingId].disputeReason = null;
        state.completed["booking_" + serviceBookingId].status = 6;

        if (state.selected.id === serviceBookingId) {
          state.selected.disputeReason = null;
          state.selected.status = 6;
        }

        delete state.disputed["booking_" + serviceBookingId];
      }
    },
  },
});

export const {
  setSelectedBooking,
  setBookings,
  setBooking,
  setQuote,
  bookingsToDecision,
  bookingsToDecline,
  bookingsToAccepted,
  bookingToInProgress,
  bookingToCompleted,
  bookingToDisputed,
  bookingDiscarded,
  bookingDisputeResolved,
} = BookingSlice.actions;
export default BookingSlice.reducer;

export const getRecentBookings = createDraftSafeSelector(
  (state) => state.booking.bookings,
  (bookings) => Object.values(bookings ?? {}).slice(0, 10)
);
