import React from "react";
import Select from "react-select";
import styles from "./style.module.css";
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    color: "#fff",
    borderColor: "#dcdcdc",
    padding: "4px 6px 4px 15px",
    minHeight: "46px",
    boxShadow: "unset",
    ":hover": { borderColor: "#dcdcdc" },
    ":focus": { borderColor: "#fe5000" },
  }), // Blue background for select
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "6px 3px", // Add padding to the dropdown indicator
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? "#fe5000" // Background color for selected option
      : isFocused
      ? "#FFE7D6" // Grayish background for options on hover
      : undefined,
    color: isDisabled
      ? "#ccc"
      : isSelected
      ? "#fff" // White text for selected option
      : "black",
    cursor: isDisabled ? "not-allowed" : "default",
    ":active": {
      ...provided[":active"],
      backgroundColor: !isDisabled
        ? isSelected
          ? "#fe5000" // Background color for selected option on active
          : "rgba(0, 0, 0, 0.3)" // Slightly darker background for options on active
        : undefined,
    },
  }),
};

const SelectBox = (props) => {
  return (
    <Select
      {...props}
      classNames={{
        control: () => styles.control,
        placeholder: () => styles.placeholder,
      }}
      styles={colourStyles}
    />
  );
};
export default SelectBox;
