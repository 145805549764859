import { Form } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { asyncCreatePassword, asyncVerifyToken } from "store/auth/authThunk";

const CreatePassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isTokenValid, setIsTokenValid] = useState(null);

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  // const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.common.loading);

  let [params] = useSearchParams();
  let resetLink = params.get("token");
  console.log(resetLink);
  const onFinish = async (values) => {
    let res = await dispatch(
      asyncCreatePassword({ resetLink, ...values })
    ).unwrap();

    if (res.success) {
      navigate("/");
    }
  };

  const verifyToken = useCallback(async () => {
    let res = await dispatch(asyncVerifyToken({ resetLink })).unwrap();
    console.log(res, "check");
    if (!res.success) {
      return navigate("/login");
    }
    setIsTokenValid(res.success);
  }, [resetLink]);

  useEffect(() => {
    if (!resetLink) return navigate("/");
    verifyToken();
  }, [resetLink]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  let hideFields =
    !loading && typeof isTokenValid == "boolean" && !isTokenValid;

  if (!hideFields && !isTokenValid)
    return (
      <div
        style={{
          width: "100vw",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          fontSize: "2rem",
        }}
      >
        Validating Token, Please Wait...
      </div>
    );

  return (
    <div className="content mt-5 p-1">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 col-lg-6 mx-auto">
            <div className="login-wrap">
              <div className="login-header">
                <h3>Create Password</h3>
                <p>{`Your new password must include numbers, character and special symbols`}</p>
              </div>
              <Form
                form={form}
                name="createPassword"
                initialValues={{
                  newPassword: "",
                  confirmPassword: "",
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                {!hideFields ? (
                  <>
                    <div className="log-form">
                      <div className="form-group">
                        <label className="col-form-label">New Password</label>
                        <Form.Item
                          name="newPassword"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Password is required!",
                            },
                            {
                              min: 8,
                              message: "Must be 8 Characters at Least",
                            },
                          ]}
                        >
                          <span>
                            <input
                              autoComplete="new-password"
                              type={showPass ? "text" : "password"}
                              className="form-control pass-input"
                              placeholder="*************"
                            />
                            <span className="toggle-password feather-eye">
                              <FeatherIcon
                                icon={showPass ? "eye-off" : "eye"}
                                onClick={() => setShowPass((s) => !s)}
                              />
                            </span>
                          </span>
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label className="col-form-label d-block">
                          Password
                        </label>
                        <div className="pass-group">
                          <Form.Item
                            name="confirmPassword"
                            dependencies={["newPassword"]}
                            validateFirst
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please confirm your password!",
                              },
                              {
                                min: 8,
                                message: "Must be 8 Characters at Least",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("newPassword") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "The new password that you entered do not match!"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <span>
                              <input
                                autoComplete="new-password"
                                type={showConfirmPass ? "text" : "password"}
                                className="form-control pass-input"
                                placeholder="*************"
                              />
                              <span className="toggle-password feather-eye">
                                <FeatherIcon
                                  icon={showConfirmPass ? "eye-off" : "eye"}
                                  onClick={() => setShowConfirmPass((s) => !s)}
                                />
                              </span>
                            </span>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary w-100 login-btn"
                      type="button"
                      onClick={() => form.submit()}
                    >
                      Create
                    </button>
                  </>
                ) : (
                  <Navigate to={"/login"} />
                )}
                <p className="no-acc">
                  {`Back to `}
                  <Link to="/login">Login</Link>
                </p>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreatePassword;
