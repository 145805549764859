import { useCallback, useEffect, useMemo, useState } from "react";

import service_01 from "assets/images/services/service-01.jpg";
import VendorServiceCard from "components/VendorServiceCard/VendorServiceCard";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetAllActiveServices,
  asyncGetAllVendorServices,
  asyncUpdateServices,
} from "store/service/serviceThunk";
import { BASE_URL_IMAGE } from "services/axios";
import SelectBox from "commoncomponent/Select/Select";
import { Form } from "antd";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";

const VendorServices = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [showAddService, setShowAddService] = useState(false);

  const [_, fetchAllServices] = useLoaderDispatch(asyncGetAllVendorServices);
  const [__, fetchAllActiveServices] = useLoaderDispatch(
    asyncGetAllActiveServices
  );

  let activeServices = useSelector((state) => state.service.activeServices);
  let services = useSelector((state) => state.service.services);

  let vendorServices = useMemo(() => Object.values(services ?? {}), [services]);

  let vendorServiceIds = vendorServices.map(
    (vendorService) => vendorService.serviceId
  );

  activeServices = useMemo(
    () => Object.values(activeServices ?? {}),
    [activeServices]
  );

  let editServices = useCallback(
    (data) => {
      return data
        ?.filter((service) => vendorServiceIds?.includes(service.id))
        ?.map((service) => ({
          label: service.name,
          value: service.id,
        }));
    },
    [vendorServiceIds]
  );

  useEffect(() => {
    fetchAllServices();
    fetchAllActiveServices();
  }, [fetchAllServices]);

  // const loadData = useCallback(async () => {
  //   dispatch(asyncGetAllVendorServices()).unwrap();
  //   dispatch(asyncGetAllActiveServices()).unwrap();
  // }, [dispatch]);

  // useEffect(() => {
  //   loadData();
  // }, [loadData]);

  useEffect(() => {
    form && form?.setFieldValue("serviceId", editServices(activeServices));
  }, [vendorServices, showAddService]);

  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);

  const onFinish = async (values) => {
    let serviceId = JSON.stringify(values.serviceId.map((item) => item.value));
    let res = await dispatch(asyncUpdateServices(serviceId)).unwrap();
    if (res.success) {
      setShowAddService(false);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid px-3 px-lg-4">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="widget-title">
                  <h4>My Services</h4>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* <div className="row">
            <div className="col-md-12">
              <div className="tab-list">
                <ul className="nav">
                  <li>
                    <Link
                      to="#"
                      className="active"
                      data-bs-toggle="tab"
                      data-bs-target="#active-service"
                    >
                      Active Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      data-bs-toggle="tab"
                      data-bs-target="#inactive-service"
                    >
                      Inactive Services
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="tab-content pt-0">
            <div className="tab-pane active" id="active-service">
              <div className="row">
                {vendorServices.map((service, index) => (
                  <div
                    key={index}
                    className="col-xxl-3 col-xl-4 col-lg-4 col-md-6"
                  >
                    <VendorServiceCard
                      name={service?.service?.name}
                      service={service?.service?.name}
                      image={
                        service?.service?.image
                          ? BASE_URL_IMAGE + service?.service?.image
                          : service_01
                      }
                      isActive={service?.service?.active}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="tab-pane fade" id="inactive-service">
              <div className="row">
                {vendorServices.map((service, index) => (
                  <div key={index} className="col-xl-4 col-md-6">
                    <VendorServiceCard
                      name={service?.service?.name}
                      service={service?.service?.name}
                      image={
                        service?.service?.image
                          ? BASE_URL_IMAGE + service?.service?.image
                          : service_01
                      }
                      isActive={service?.service?.active}
                    />
                  </div>
                ))}
              </div>
            </div> */}
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <Form form={form} name="serviceForm" onFinish={onFinish}>
                {showAddService && (
                  <Form.Item
                    name="serviceId"
                    rules={[
                      {
                        required: true,
                        message: "Service is required!",
                      },
                    ]}
                  >
                    <SelectBox
                      isMulti={true}
                      options={activeServices?.map((service) => ({
                        label: service.name,
                        value: service.id,
                      }))}
                    />
                  </Form.Item>
                )}
              </Form>
              {!showAddService ? (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setShowAddService(true);
                  }}
                >
                  Add Or Remove Services
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-disabled me-2"
                    onClick={() => {
                      setShowAddService(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      form.submit();
                    }}
                    className="btn btn-primary"
                  >
                    Save Changes
                  </button>
                </>
              )}
            </div>
          </div>
          {/* Pagination */}
          {/* <div className="row">
            <div className="col-sm-6">
              <div className="review-entries">
                <span>Show</span>
                <select>
                  <option>07</option>
                  <option>08</option>
                </select>
                <span>entries</span>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="review-pagination">
                <p>1 - 09 of 09</p>
                <ul className="pagination">
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      1
                    </Link>
                  </li>
                  <li className="page-item active">
                    <Link className="page-link" to="#">
                      2 <span className="visually-hidden">(current)</span>
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="#">
                      3
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          {/* <Paginations /> */}
          {/* /Pagination */}
        </div>
      </div>
    </>
  );
};
export default VendorServices;
