// import pro_chart_01 from "assets/images/icons/pro-chart-01.svg";
// import pro_chart_02 from "assets/images/icons/pro-chart-02.svg";
// import pro_icon_03 from "assets/images/icons/pro-icon-03.svg";
// import pro_icon_04 from "assets/images/icons/pro-icon-04.svg";
// import service_06 from "assets/images/services/service-06.jpg";

import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import Status from "components/Status/Status";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getRecentBookings,
  // setSelectedBooking,
} from "store/booking/bookingSlice";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL_IMAGE } from "services/axios";
// import booking from "assets/json/booking";
import { formatDate } from "utils";
import { asyncGetAllVendorBookingsRequest } from "store/booking/bookingThunk";
import { useLoaderDispatch } from "hooks/useLoaderDispatch";

const VendorDashboard = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  const [bookingLoader, fetchBookings] = useLoaderDispatch(
    asyncGetAllVendorBookingsRequest
  );

  let recentBookings = useSelector(getRecentBookings);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  useEffect(() => {
    document.body.classList.add("provider-body");

    return () => document.body.classList.remove("provider-body");
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid px-3 px-lg-4">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-12">
                <div className="provider-subtitle">
                  <h6>Dashboard</h6>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row ">
            {/* <!-- Recent Booking --> */}
            <div className="col-12 d-flex flex-column">
              <h6 className="user-title">Recent Bookings</h6>
              <div className="table-responsive recent-booking flex-fill">
                <table className="table mb-0">
                  <tbody>
                    {recentBookings?.length > 0
                      ? recentBookings?.map((serviceBooking) => {
                          return (
                            <tr key={serviceBooking?.id}>
                              <td className="dash-tbl-title">
                                <h2 className="table-avatar">
                                  <Link
                                    to="bookings"
                                    className="avatar avatar-m me-2"
                                  >
                                    <img
                                      className="avatar-img rounded"
                                      src={
                                        BASE_URL_IMAGE +
                                        serviceBooking?.service?.parentService
                                          ?.image
                                      }
                                      alt="User Image"
                                    />
                                  </Link>
                                  <Link to="bookings">
                                    {serviceBooking?.service?.name}
                                    <span className="dash-tbl-booking-span">
                                      <FeatherIcon
                                        icon={"calendar"}
                                        size={16}
                                        className="me-1"
                                      />
                                      {formatDate(
                                        serviceBooking?.booking?.createdAt
                                      )}
                                    </span>
                                  </Link>
                                </h2>
                                <span className="dash-tbl-status">
                                  <Status title="Waiting for Qoute" />
                                </span>
                              </td>
                              <td className="text-end service-widget">
                                <Link
                                  to={`bookings/` + serviceBooking?.id}
                                  role="button"
                                  className="btn btn-primary"
                                  // onClick={() => {
                                  // navigate("bookings/" + serviceBooking.id);
                                  // dispatch(
                                  //   setSelectedBooking(serviceBooking)
                                  // );
                                  // }}
                                >
                                  View Details
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      : bookingLoader
                      ? "Loading Recent Bookings, Please Wait..."
                      : "No Recent Booking"}
                    {/* <tr>
                      <td className="dash-tbl-title">
                        <h2 className="table-avatar">
                          <a href="#" className="avatar avatar-m me-2">
                            <img
                              className="avatar-img rounded"
                              src={service_06}
                              alt="User Image"
                            />
                          </a>
                          <a href="#">
                            Computer Repair
                            <span className="dash-tbl-booking-span">
                              <FeatherIcon
                                icon={"calendar"}
                                size={16}
                                className="me-1"
                              />{" "}
                              22 Sep 2022
                            </span>
                          </a>
                        </h2>
                        <span className="dash-tbl-status">
                          <Status title="Waiting for Qoute" />
                        </span>
                      </td>
                      <td className="text-end service-widget">
                        <a href="#" className="btn btn-book">
                          View Details
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="dash-tbl-title">
                        <h2 className="table-avatar">
                          <a href="#" className="avatar avatar-m me-2">
                            <img
                              className="avatar-img rounded"
                              src={service_06}
                              alt="User Image"
                            />
                          </a>
                          <a href="#">
                            Computer Repair
                            <span className="dash-tbl-booking-span">
                              <FeatherIcon
                                icon={"calendar"}
                                size={16}
                                className="me-1"
                              />{" "}
                              22 Sep 2022
                            </span>
                          </a>
                        </h2>
                        <span className="dash-tbl-status">
                          <Status title="Waiting for Qoute" />
                        </span>
                      </td>
                      <td className="text-end service-widget">
                        <a href="#" className="btn btn-book">
                          View Details
                        </a>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <!-- /Recent Booking --> */}
            {/* <div className="col-12 col-sm-6 mt-4 ">
              <h6 className="user-title">Total Earnings</h6>
              <div className="d-flex">
                <div className="dash-card flex-fill">
                  <div className="dash-header">
                    <div className="dash-widget-header">
                      <div className="d-flex align-items-center">
                        <span className="dash-widget-icon">
                          <img src={pro_icon_04} alt="img" />
                        </span>
                        <div className="dash-widget-info">
                          <h6>Earnings</h6>
                          <h5>$50000</h5>
                        </div>
                      </div>
                      <select>
                        <option>2022</option>
                        <option>2021</option>
                      </select>
                    </div>
                    <div className="dash-total">
                      <p>
                        Total Sales: <span>85</span>
                      </p>
                      <div className="badge-success">+2.58</div>
                    </div>
                  </div>
                  <div className="dash-widget-img">
                    <img src={pro_chart_01} className="img-fluid" alt="img" />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-12 col-sm-6 mt-4">
              <h6 className="user-title">Total Bookings</h6>
              <div className="d-flex">
                <div className="dash-card flex-fill">
                  <div className="dash-header">
                    <div className="dash-widget-header">
                      <div className="d-flex align-items-center">
                        <span className="dash-widget-icon">
                          <img src={pro_icon_03} alt="img" />
                        </span>
                        <div className="dash-widget-info">
                          <h6>Bookings</h6>
                          <h5>102</h5>
                        </div>
                      </div>
                      <select>
                        <option>2022</option>
                        <option>2021</option>
                      </select>
                    </div>
                    <div className="dash-total">
                      <p>
                        Over All: <span>60</span>
                      </p>
                      <div className="badge-success">+2.58</div>
                    </div>
                  </div>
                  <div className="dash-widget-img">
                    <img src={pro_chart_02} className="img-fluid" alt="img" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default VendorDashboard;
