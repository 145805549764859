import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  token: null,
  refreshToken: null,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, { payload }) => ({ ...state, ...payload }),
    setUser: (state, { payload }) => ({ ...state, user: payload }),
    resetAuth: () => {
      return {
        user: {},
        token: null,
        refreshToken: null,
      };
    },
  },
});

export const { setAuth, resetAuth, setUser } = AuthSlice.actions;
export default AuthSlice.reducer;

export const selectToken = (state) => state.auth.token;
