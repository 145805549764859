// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_bookingTabs__GZcta {
  flex: 1 1;
  flex-wrap: unset !important;
  overflow-x: auto;
}
.styles_bookingTabs__GZcta .styles_tab__pKAeA .styles_tabLink__Ri6xJ {
  width: max-content;
  display: inline-block;
}
.styles_bookingTabs__GZcta::-webkit-scrollbar {
  display: none;
}

/* --------- Setting Page --------- */
.styles_settingSelect__RgTY5{
  height: 46px !important;
}

.styles_PhoneInput--disabled__r6-A4{
  background-color: red !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/vendor/styles.module.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,2BAA2B;EAC3B,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,aAAa;AACf;;AAEA,qCAAqC;AACrC;EACE,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".bookingTabs {\n  flex: 1;\n  flex-wrap: unset !important;\n  overflow-x: auto;\n}\n.bookingTabs .tab .tabLink {\n  width: max-content;\n  display: inline-block;\n}\n.bookingTabs::-webkit-scrollbar {\n  display: none;\n}\n\n/* --------- Setting Page --------- */\n.settingSelect{\n  height: 46px !important;\n}\n\n.PhoneInput--disabled{\n  background-color: red !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bookingTabs": `styles_bookingTabs__GZcta`,
	"tab": `styles_tab__pKAeA`,
	"tabLink": `styles_tabLink__Ri6xJ`,
	"settingSelect": `styles_settingSelect__RgTY5`,
	"PhoneInput--disabled": `styles_PhoneInput--disabled__r6-A4`
};
export default ___CSS_LOADER_EXPORT___;
