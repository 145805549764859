import { createAsyncThunk } from "@reduxjs/toolkit";
import { setError, setNotification, setSuccess } from "./commonSlice";
import { toast } from "react-toastify";

export const asyncAlertError = createAsyncThunk("error", async (msg) => {
  // dispatch(setError(msg));
  toast.error(msg);
  // setTimeout(() => {
  //   dispatch(setError(null));
  // }, 3000);
});

export const asyncAlertSuccess = createAsyncThunk("success", async (msg) => {
  // dispatch(setSuccess(msg));
  toast.success(msg);
  // setTimeout(() => {
  //   dispatch(setSuccess(null));
  // }, 3000);
});

export const asyncAlertNotification = createAsyncThunk(
  "notification",
  async (payload, { dispatch }) => {
    dispatch(setNotification(payload));
  }
);
