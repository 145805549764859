import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

function VendorServiceCard({ name, image, service, isActive }) {
  return (
    <div className="service-widget pro-service">
      <div className="service-img">
        {/* to="/service-details" */}
        <Link to="#" className="services-img-link">
          <img className="img-fluid serv-img" alt="Service Image" src={image} />
        </Link>
        <div className="fav-item">
          <div className="item-info">
            {/* to="/categories" */}
            <Link to="#">
              <span className="item-cat">{service}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-content">
        <h3 className="title">
          {/* to="/service-details" */}
          <Link to="#">{name}</Link>
          {/* <Link to="#" data-bs-toggle="modal" data-bs-target="#in-active"> */}
          {/* <span>
            <FeatherIcon icon={"circle"} /> {isActive ? "Active" : "InActive"}
          </span> */}
          {/* </Link> */}
        </h3>
      </div>
    </div>
  );
}

export default VendorServiceCard;
