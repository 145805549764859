import Phone, { isValidPhoneNumber } from "react-phone-number-input";

export const phoneInputValidator = () => ({
  validator(_, value) {
    let isValid = isValidPhoneNumber(value ?? "", "AU");
    if (value?.length > 3 && !value?.startsWith("+614")) {
      return Promise.reject(
        new Error("Phone must start with 04 i-e (04 1234 5678)")
      );
    } else if (isValid || value?.length < 12) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Phone number is invalid!"));
    }
  },
});

const PhoneInput = ({ value, onChange, ...props }) => {
  return (
    <Phone
      {...props}
      value={value}
      onChange={onChange}
      addInternationalOption={false}
      international={false}
      limitMaxLength={true}
      placeholder="0432-123-123"
      defaultCountry="AU"
      countries={["AU"]}
      numberInputProps={{
        className: "py-0 form-control",
      }}
    />
  );
};

export default PhoneInput;
