import { Form, Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import PhoneInput, { phoneInputValidator } from "commoncomponent/PhoneInput";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL_IMAGE } from "services/axios";
import { asyncUpdateVendorProfile } from "store/auth/authThunk";
import { asyncAlertError } from "store/common/commonThunk";

const VendorSettings = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(true);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    document.body.classList.add("provider-body");
    return () => document.body.classList.remove("provider-body");
  }, []);

  useEffect(() => {
    let imageObj = {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: BASE_URL_IMAGE + user?.image,
    };

    form.setFieldsValue({
      name: user?.name ?? "",
      email: user?.email ?? "",
      mobileNumber: user?.mobileNumber ?? "",
      file: imageObj,
    });

    if (user?.image) {
      setFileList([imageObj]);
    }
  }, [isEdit, user?.id, user?.updatedAt]);

  const onFinish = async ({ email, ...values }) => {
    if (isEdit) {
      let formData = new FormData();
      let body = {};

      if (values.name !== user?.name) {
        body.name = values.name;
        formData.append("name", values.name);
      }

      if (values.mobileNumber !== user?.mobileNumber) {
        body.mobileNumber = values.mobileNumber;
        formData.append("mobileNumber", values.mobileNumber);
      }

      if (values.password) {
        body.password = values.password;
        formData.append("password", values.password);
      }

      if (fileList[0]?.originFileObj) {
        body.file = values.file;
        formData.append("file", values.file);
      }

      if (Object.keys(body).length === 0)
        return dispatch(asyncAlertError("No changes have been made!"));

      let res = await dispatch(asyncUpdateVendorProfile(formData)).unwrap();
      if (res) {
        setIsEdit(false);
        form.resetFields(["password", "confirmPassword"]);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    form.setFieldsValue({
      file: newFileList[0]?.originFileObj ?? null,
    });
    form.validateFields(["file"]);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid px-3 px-lg-4">
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-md-4">
                  <div className="provider-subtitle">
                    <h6>Account Settings</h6>
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <h6 className="user-title">Profile Picture</h6>
            <div className="pro-picture mb-0">
              <Form.Item
                name="file"
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: `Image is required!`,
                  },
                ]}
              >
                <ImgCrop accept=".png,.jpeg,.jpg">
                  <Upload
                    disabled={!isEdit}
                    multiple={false}
                    listType="picture-card"
                    customRequest={({ onSuccess }) => onSuccess("ok")}
                    fileList={fileList}
                    showUploadList={true}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    maxCount={1}
                  >
                    {fileList.length === 1 ? null : "Upload"}
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </div>
            {/* <h6 className="user-title">General Information</h6> */}
            <div className="general-info">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Name is required!",
                        },
                      ]}
                    >
                      <input
                        disabled={!isEdit}
                        type="text"
                        className="form-control"
                        placeholder="Enter your name"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Email</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          whitespace: true,
                          message: "Email is required!",
                        },
                      ]}
                    >
                      <input
                        disabled={true}
                        type="email"
                        className="form-control"
                        placeholder="example@email.com"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Mobile Number</label>
                    <Form.Item
                      name="mobileNumber"
                      validateFirst
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Phone is required!",
                        },
                        phoneInputValidator,
                      ]}
                    >
                      <PhoneInput disabled={!isEdit} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">New Password</label>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          whitespace: true,
                          message: "Password is required!",
                        },
                        {
                          min: 8,
                          message: "Must be 8 Characters at Least",
                        },
                      ]}
                    >
                      <span>
                        <input
                          disabled={!isEdit}
                          autoComplete="new-password"
                          type={showPass ? "text" : "password"}
                          className="form-control pass-input"
                          placeholder="*************"
                        />
                        <span className="toggle-password feather-eye">
                          <FeatherIcon
                            icon={showPass ? "eye-off" : "eye"}
                            onClick={() => setShowPass((s) => !s)}
                          />
                        </span>
                      </span>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Confirm Password</label>
                    <Form.Item
                      name="confirmPassword"
                      dependencies={["password"]}
                      validateFirst
                      rules={[
                        {
                          whitespace: true,
                          message: "Please confirm your password!",
                        },
                        {
                          min: 8,
                          message: "Must be 8 Characters at Least",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let password = getFieldValue("password");
                            if (password === value || (!password && !value)) {
                              return Promise.resolve();
                            } else if (password?.length >= 8) {
                              if (!value) {
                                return Promise.reject(
                                  new Error("Please confirm your password!")
                                );
                              } else {
                                return Promise.reject(
                                  new Error(
                                    "The new password that you entered do not match!"
                                  )
                                );
                              }
                            }
                          },
                        }),
                      ]}
                    >
                      <span>
                        <input
                          disabled={!isEdit}
                          autoComplete="new-password"
                          type={showConfirmPass ? "text" : "password"}
                          className="form-control pass-input"
                          placeholder="*************"
                        />
                        <span className="toggle-password feather-eye">
                          <FeatherIcon
                            icon={showConfirmPass ? "eye-off" : "eye"}
                            onClick={() => setShowConfirmPass((s) => !s)}
                          />
                        </span>
                      </span>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            {/* <h6 className="user-title">Address</h6>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">
                    Address<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your address"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    Country<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your state"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    State<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your state"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    City<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your city"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    Postal Code<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your postal code"
                  />
                </div>
              </div>
            </div> */}
            <div className="acc-submit">
              {!isEdit ? (
                <button
                  type="button"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  className="btn btn-secondary"
                >
                  Edit
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setIsEdit(false)}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary">Save Changes</button>
                </>
              )}
            </div>
          </Form>
        </div>
      </div>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
export default VendorSettings;
