import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

export const useLoaderDispatch = (loadData) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    if (loadData) {
      setLoading(true);
      const res = await dispatch(loadData()).unwrap();
      setLoading(false);
      return res;
    }
    return { success: false };
  }, [dispatch, loadData]);

  return [loading, fetchData];
};
