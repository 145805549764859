import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { asyncVerifyEmail } from "store/auth/authThunk";

const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isTokenValid, setIsTokenValid] = useState(null);

  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.common.loading);

  let [params] = useSearchParams();
  let resetLink = params.get("token");

  const verifyToken = useCallback(async () => {
    let res = await dispatch(asyncVerifyEmail(resetLink)).unwrap();

    setTimeout(() => {
      setIsTokenValid(res.success);
    }, 1000);
  }, [resetLink]);

  useEffect(() => {
    if (!resetLink && token) return navigate("/");
    if (!token) {
      verifyToken();
    } else {
      navigate("/");
    }
  }, [resetLink]);

  let hideFields =
    !loading && typeof isTokenValid == "boolean" && !isTokenValid;

  if (!hideFields && !isTokenValid) {
    return (
      <div
        style={{
          width: "100vw",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          fontSize: "2rem",
        }}
      >
        Validating Token, Please Wait...
      </div>
    );
  } else {
    return <Navigate to={"/login"} />;
  }
};

export default Verification;
