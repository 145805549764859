import React from "react";
import styles from "./style.module.css";

const Status = ({ title }) => {
  let color = "";
  let bgColor = "";

  switch (title) {
    case "Waiting for Quote":
      color = "#B54708";
      bgColor = "#FFFAEB";
      break;
    case "Completed":
      color = "#6DCC76";
      bgColor = "#F3FFF6";
      break;
    case "In Progress":
      color = "#326CFB";
      bgColor = "#F4F7FF";
      break;
    case "Cancelled":
      color = "#F82424";
      bgColor = "#FFF5F5";
      break;
    default:
      color = "#B54708";
      bgColor = "#FFFAEB";
  }

  return (
    <div className={styles.appStatue} style={{ backgroundColor: bgColor }}>
      <div className={styles.dot} style={{ backgroundColor: color }} />
      <p style={{ color: color }}>{title}</p>
    </div>
  );
};
export default Status;
