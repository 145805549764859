import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payments: {},
  totalEarn: 0,
  totalPending: 0,
};

const TransactionSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setTransactions: (state, { payload }) => {
      state.payments = payload.payments?.reduce(
        (acc, payment) => ({ ...acc, [payment.id]: payment }),
        {}
      );
      state.totalEarn = payload.totalEarn;
      state.totalPending = payload.totalPending;
    },
    setTransaction: (state, { payload }) => {
      if (!state.payments["payment_" + payload.id]) {
        state.payments = {
          ["payment_" + payload.id]: payload,
          ...state.payments,
        };
      } else {
        state.payments["payment_" + payload.id] = payload;
      }
    },
  },
});

export const { setTransactions, setTransaction } = TransactionSlice.actions;
export default TransactionSlice.reducer;
