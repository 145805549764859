import { useEffect, useState, useSyncExternalStore } from "react";

function getSnapshot() {
  return navigator.onLine;
}

function subscribe(callback) {
  window.addEventListener("online", callback);
  window.addEventListener("offline", callback);
  return () => {
    window.removeEventListener("online", callback);
    window.removeEventListener("offline", callback);
  };
}

export const useInternetStatus = () => {
  const [show, setShow] = useState(null);
  const isOnline = useSyncExternalStore(subscribe, getSnapshot);

  useEffect(() => {
    if (show === null) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [isOnline]);

  return { isOnline, show };
};
