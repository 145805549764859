import { createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../services/api";
import { setNotifications } from "./notificationSlice";

export const asyncGetAllNotifications = createAsyncThunk(
  "notification/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "users/notifications",
        method: "GET",
        token,
      });

      let dataSource = {};

      if (res.success) {
        dataSource = res.data?.slice(0, 5).reduce((acc, curr) => {
          return {
            ...acc,
            ["notification_" + curr.id]: curr,
          };
        }, {});

        dispatch(setNotifications(dataSource));
        return { success: true };
      } else {
        dispatch(setNotifications({}));
      }

      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);
