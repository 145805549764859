import { Form } from "antd";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { asyncForgotPassword } from "store/auth/authThunk";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = useCallback(async (values) => {
    const { success } = await dispatch(asyncForgotPassword(values)).unwrap();
    if (success) {
      form.resetFields();
    }
  }, []);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="content mt-5 p-1">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 col-lg-6 mx-auto">
            <div className="login-wrap">
              <div className="login-header">
                <h4>Forgot Password</h4>
              </div>
              <Form
                form={form}
                name="login"
                initialValues={{
                  email: "",
                  password: "",
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div className="form-group">
                  <label className="col-form-label">E-mail</label>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        whitespace: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="example@email.com"
                    />
                  </Form.Item>
                </div>
                <button
                  className="btn btn-primary w-100 "
                  type="button"
                  onClick={() => form.submit()}
                >
                  Submit
                </button>
              </Form>
              <p className="no-acc">
                {`back to login ? `}
                <Link to="/login">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
