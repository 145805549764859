import { Spin, Progress } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useInternetStatus } from "hooks/useInternetStatus";
import { useEffect, useState } from "react";

export const InternetStatus = () => {
  const { isOnline, show } = useInternetStatus();
  const [timer, setTimer] = useState(10);

  useEffect(() => {
    let interval = null;

    if (show && isOnline) {
      interval = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
      setTimer(10);
    };
  }, [show, isOnline]);

  if (timer === 0) {
    location.reload();
  }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: "#37375E" }} spin />
  );

  if (!show) return null;

  return (
    <div
      className="d-flex justify-content-between align-items-center position-fixed start-50"
      style={{
        transform: "translateX(-50%)",
        backgroundColor: "#FFC5A5",
        padding: "5px 20px",
        borderRadius: 5,
        zIndex: 999,
        top: 20,
      }}
    >
      {!isOnline ? (
        <>
          <div style={{ fontSize: 17 }}>
            <strong>Warning! </strong>No Internet Connection. Reconnecting ...!
          </div>
          <Spin style={{ marginLeft: 20 }} indicator={antIcon} />
        </>
      ) : (
        <>
          <div style={{ fontSize: 17 }}>
            You are now connected!{" "}
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                location.reload();
              }}
            >
              <strong>Please Refresh {timer}</strong>
            </span>
          </div>

          <Progress
            size={30}
            style={{ marginLeft: 20 }}
            type="dashboard"
            strokeColor="#FFF"
            percent={100}
          />
        </>
      )}
    </div>
  );
};
