import { createAsyncThunk } from "@reduxjs/toolkit";
import { asyncAlertError, asyncAlertSuccess } from "store/common/commonThunk";
import { setLoading } from "store/common/commonSlice";
import { callApi } from "services/api";
import { setActiveServices, setServices } from "./serviceSlice";

export const asyncGetAllActiveServices = createAsyncThunk(
  "admin/service/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "admin/active-services",
        method: "GET",
        token,
      });

      if (res.success) {
        let dataSource = res.data.reduce((acc, curr) => {
          return {
            ...acc,
            ["service_" + curr.id]: curr,
          };
        }, {});

        dispatch(setActiveServices(dataSource));

        return { success: true };
      }

      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncGetAllVendorServices = createAsyncThunk(
  "service/list",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().auth.token;

      const res = await callApi({
        path: "vendor/services",
        method: "GET",
        token,
      });

      if (res.success) {
        dispatch(setServices(res.data));
        return { success: true };
      } else {
        dispatch(setServices([]));
      }

      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }
);

export const asyncUpdateServices = createAsyncThunk(
  "service/update",
  async (serviceId, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true));

      const token = getState().auth.token;
      const vendorId = getState().auth.user.id;

      const res = await callApi({
        path: `admin/vendors/profile/${vendorId}`,
        method: "PUT",
        body: {
          serviceId,
        },
        token,
      });

      if (res.success) {
        dispatch(asyncAlertSuccess("Services Successfully Updated!"));
        return { success: true };
      }

      dispatch(asyncAlertError(res.message));
      return { success: false };
    } catch (error) {
      dispatch(asyncAlertError(error.message));
      return { success: false };
    } finally {
      dispatch(setLoading(false));
    }
  }
);
